//在对象开头添加数据，或修改对象
//object 可空，原始的对象
//key 编辑对象的key
//value 编辑对象的value

function unshift(list) {

    if (list.key == null || list.key == "") {
        return list.object;
    }
    if (list.value == null || list.value == "") {
        return list.object;
    }
    var obj_new = {};
    if (typeof list.object != "object") {
        obj_new[list.key] = list.value;
        return obj_new;
    }

    // 修改对象
    if (typeof list.object[list.key] == "object") {
        list.object[list.key] = list.value;
        return list.object;
    }

    //在对象开头添加数据
    obj_new[list.key] = list.value;
    for (var i in list.object) {
        obj_new[i] = list.object[i];
    }

    return obj_new;
}
export default unshift;