function table(rthis, el) {
  if (!rthis) {
    ElMessageBox.alert('方法错误：table this不能为空', '提示信息', {
      confirmButtonText: '确认',
      //dangerouslyUseHTMLString: true,   //是否输出html内容
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (rthis.$el instanceof HTMLElement != true) {
    ElMessageBox.alert('组件需要添加根节点', '提示信息', {
      confirmButtonText: '确认',
      //dangerouslyUseHTMLString: true,   //是否输出html内容
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (el == null || el == "") {
    return;
  }
  rthis.$nextTick(function () {
    if (el.class == null || el.class == "") {
      return;
    }

    // if (document.getElementsByClassName(el.class).length > 1) {
    //     console.log('%c' + ' 方法错误：table', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n 存在多个" + el.class + "元素", '\n 路径：' + rthis.$options.__file);
    //     return
    // }

    var domlist = document.getElementsByClassName(el.class);
    var dom;
    for (var i in domlist) {
      dom = domlist[i];
      if (dom instanceof HTMLElement != true) {
        continue;
      }
      // console.log("渲染：", dom)
      // dom = document.getElementsByClassName(el.class)[0]
      // console.log('元素', document.getElementsByClassName(el.class).length);

      //# 去除表格间隙
      dom.style.borderCollapse = "collapse";
      dom.style.borderSpacing = 0;

      //@ 遍历标题
      var chiltdlist = dom.getElementsByTagName('thead');
      var chiltd;
      for (var i in chiltdlist) {
        chiltd = chiltdlist[i];
        if (chiltd instanceof HTMLElement != true) {
          continue;
        }
        chiltd = chiltd.getElementsByTagName('td');
        //将htmlcollection对象转成数组
        Array.from(chiltd).forEach(std => {
          std.style.padding = '8px 15px';
          std.style.border = '1px solid rgb(230, 230, 230)';
          std.style.fontWeight = 'bold';
          std.style.backgroundColor = 'rgb(250, 250, 250)';
          std.style.borderBottomWidth = '2px';
          std.style.color = '#5d6b71';
        });
      }
      //@ 遍历表格TD
      chiltdlist = dom.getElementsByTagName('tbody');
      for (var i in chiltdlist) {
        chiltd = chiltdlist[i];
        if (chiltd instanceof HTMLElement != true) {
          continue;
        }
        chiltd = chiltd.getElementsByTagName('tr');
        if (chiltd != null) {
          var index = 1;
          // 遍历tr 每行
          //将htmlcollection对象转成数组
          Array.from(chiltd).forEach(str => {
            // 遍历td 每列
            Array.from(str.getElementsByTagName('td')).forEach(std => {
              std.style.border = '1px #e6e6e6 solid';
              std.style.color = '#5d6b71';
              if (std.style.padding == '') {
                std.style.padding = "8px 15px";
              }
            });
            //判断是否偶数，设置行背景色
            if (index % 2 == 0) {
              str.style.backgroundColor = "#fafafa";
            }
            //# 鼠标进入标签触发
            str.onmouseover = function () {
              // 双击选中状态
              if (this.style.borderBottomWidth == '1px') {
                return;
              }
              if (el.transition == true) {
                this.style.transition = 'all 0.2s linear';
              }
              this.style.backgroundColor = "#e2eff8";
            };
            //# 鼠标离开标签触发
            str.onmouseout = function () {
              // 双击选中状态
              if (this.style.borderBottomWidth == '1px') {
                return;
              }
              if (el.transition == true) {
                this.style.transition = 'all 0.2s linear';
              }

              //判断是否偶数，设置行背景色
              //rowIndex 获取当前tr行的索引
              if (this.rowIndex % 2 == 0) {
                this.style.backgroundColor = "#fafafa";
              } else {
                this.style.backgroundColor = "";
              }
            };
            //# 用户双击表格时，将表格颜色选中 
            str.ondblclick = function () {
              if (el.transition == true) {
                this.style.transition = 'all 0.2s linear';
              }
              // 双击选中状态
              if (this.style.borderBottomWidth == '1px') {
                //判断是否偶数，设置行背景色
                //rowIndex 获取当前tr行的索引
                if (this.rowIndex % 2 == 0) {
                  this.style.backgroundColor = "#fafafa";
                } else {
                  this.style.backgroundColor = "";
                }
                this.style.borderBottom = "0px";
              } else {
                this.style.backgroundColor = "#ffe9b4";
                this.style.borderBottom = "#ffe9b4 1px solid";
              }
            };
            index += 1;
          });
        }
      }
      //@ 生成表格筛选
      var chiltd = dom.getElementsByTagName('thead')[0];
      if (chiltd != null) {
        chiltd = chiltd.getElementsByTagName('td');
        if (dom.getElementsByClassName('table_tools_list')[0] == null) {
          //# 生成表格左上角标签  
          var html = "<div class='table_tools' style='cursor:pointer;position: absolute;margin-top: -42px;margin-left: -5px;'>";
          html = html + "<i class='table_num' style='background-color: rgb(230, 230, 230);border-radius: 10px;color: rgb(255, 255, 255);display: inline-block;text-align: center;font-style: normal;font-size: 12px;transform: scale(0.8);padding: 0px 9px;'>20/1</i>";
          html = html + "<div class='table_tools_list ' style='z-index: 1000000000;display:none;position: absolute;font-size: 12px;max-height: 40vh;background-color: #fffcf6;color: #151515;border: solid 1px #ffa255;border-radius: 4px;box-shadow: 2px 2px 4px 0px rgb(254 115 0 / 37%);overflow-x: hidden;margin-top: -1px;margin-left: 5px;padding: 10px 0px;    padding: 10px 0px;'></div>";
          html = html + "</div>";
          dom.getElementsByTagName('thead')[0].insertAdjacentHTML("afterend", html);

          //# 获取#号面的后缀地址 
          //! 读取储存在本地的表格筛选条件 
          //! 格式["screeningform"][文件相对路径][表格索引][每列标题]
          var list = localStorage.getItem("screeningform");
          try {
            //! 将json数据转换成对象
            list = JSON.parse(list);
          } catch (e) {
            list = {};
          }
          if (list == null) {
            list = {};
          }
          //! 将文件名作为对象键 
          var para = window.top.window.location.hash;
          if (typeof list[para] != "object") {
            list[para] = {};
          }

          //将htmlcollection对象转成数组
          var index_is = 0;
          var ell;
          var total = 0;
          var xianshi_total = 0;
          Array.from(chiltd).forEach(std => {
            //! 添加列表选项
            //! 只保留英文，数字，和点
            var src = std.innerText;
            if (src == "") {
              //! 如果内容为空跳过
              return;
            }
            //! 处理每列的标题 
            src = src.replace(/<\/?.+?\/?>/g, '');
            src = src.replace(/[^\u4e00-\u9fa5^0-9^a-z^A-Z.]/ig, "");
            src = src.replace(/[\^]/ig, "");

            //! 将索引值作为对象键
            if (typeof list[para][dom.className] != "object") {
              list[para][dom.className] = {};
            }

            //! 取出左上角标签的元素，里面显示列的数字 
            ell = dom.getElementsByClassName('table_num')[0];
            //# 取出总的列数量 
            total = parseInt(ell.getAttribute("data-total"));
            if (isNaN(total)) {
              total = 0;
            }
            ;
            //!总数列加1
            total = total + 1;
            //# 取出累计显示的列数量 
            xianshi_total = parseInt(ell.getAttribute("data-xianshi_total"));
            if (isNaN(xianshi_total)) {
              xianshi_total = 0;
            }
            ;
            //! 累计显示列加1
            if (list[para][dom.className][src] != 0) {
              xianshi_total = xianshi_total + 1;
            }
            //! 填入内容
            ell.setAttribute("data-xianshi_total", xianshi_total);
            ell.setAttribute("data-total", total);
            ell.innerText = xianshi_total + ' / ' + total;

            //! 生成每列的标签 
            if (list[para][dom.className][src] != 0) {
              html = '<div table_data_index=' + index_is + ' table_data_open=1 style="padding: 3px 15px;cursor: pointer;word-break: keep-all;white-space:nowrap;">';
              html = html + '<i style="background-color: #ff9e4f;width: 13px;height: 13px;line-height: 13px;display: inline-block;border-radius: 3px;color: #fff;margin-right: 5px;">√</i>';
            } else {
              html = '<div table_data_index=' + index_is + ' table_data_open=0 style="padding: 3px 15px;cursor: pointer;word-break: keep-all;white-space:nowrap;">';
              html = html + '<i style="background-color: #e6e6e6;width: 13px;height: 13px;line-height: 13px;display: inline-block;border-radius: 3px;color: #fff;margin-right: 5px;">√</i>';

              //! 隐藏表格中的每一列 
              Array.from(dom.getElementsByTagName('tbody')[0].getElementsByTagName('tr')).forEach(stds => {
                stds.getElementsByTagName('td')[index_is].style.display = "none";
              });
              dom.getElementsByTagName('thead')[0].getElementsByTagName('td')[index_is].style.display = "none";
            }
            html = html + src;
            html = html + '</div>';
            //! 写入标签
            dom.getElementsByClassName('table_tools_list')[0].insertAdjacentHTML("beforeend", html);
            index_is += 1;
          });
        }
        //@ 点击隐藏与显示
        Array.from(dom.getElementsByClassName('table_tools_list')[0].getElementsByTagName('div')).forEach(std => {
          std.onclick = function () {
            //! 为0时关闭，为1时开启 
            var open = this.getAttribute('table_data_open');
            //! 表格的索引 
            var index = this.getAttribute('table_data_index');

            //# 若上次为打开，则关闭
            if (open == 1) {
              //! 点击的标签 
              var elel = this.parentNode.parentNode.getElementsByTagName('i')[0];
              //! 总的标题数量 
              total = parseInt(elel.getAttribute("data-total"));
              if (isNaN(total)) {
                total = 0;
              }
              ;
              //! 已显示的标题数量
              xianshi_total = parseInt(elel.getAttribute("data-xianshi_total"));
              if (isNaN(xianshi_total)) {
                xianshi_total = 0;
              }
              ;
              xianshi_total = xianshi_total - 1;
              // 保留最后一个
              if (xianshi_total < 1) {
                console.log(xianshi_total);
                return;
              }
              //! 附值标题数量
              elel.innerText = xianshi_total + ' / ' + total;
              elel.setAttribute('data-xianshi_total', xianshi_total);
              elel.setAttribute('data-total', total);
              var ell = this.parentNode.parentNode.parentNode;
              ell.getElementsByTagName('thead')[0].getElementsByTagName('td')[index].style.display = "none";
              //! 隐藏表格中的每一列 
              ell = ell.getElementsByTagName('tbody')[0];
              Array.from(ell.getElementsByTagName('tr')).forEach(str => {
                str.getElementsByTagName('td')[index].style.display = "none";
              });
              this.getElementsByTagName('i')[0].style.backgroundColor = "#E6E6E6";
              this.setAttribute('table_data_open', 0);
            }
            //# 若上次为关闭，则打开
            if (open == 0) {
              this.getElementsByTagName('i')[0].style.backgroundColor = "#ff9e4f";
              this.setAttribute('table_data_open', 1);
              var ell = this.parentNode.parentNode.parentNode;
              ell.getElementsByTagName('thead')[0].getElementsByTagName('td')[index].style.display = "";
              //! 显示表格的每一行 
              ell = ell.getElementsByTagName('tbody')[0];
              Array.from(ell.getElementsByTagName('tr')).forEach(str => {
                str.getElementsByTagName('td')[index].style.display = "";
              });

              //! 点击的标签 
              var elel = this.parentNode.parentNode.getElementsByTagName('i')[0];

              //! 总的标题数量 
              total = parseInt(elel.getAttribute("data-total"));
              if (isNaN(total)) {
                total = 0;
              }
              ;
              //! 已显示的标题数量
              xianshi_total = parseInt(elel.getAttribute("data-xianshi_total"));
              if (isNaN(xianshi_total)) {
                xianshi_total = 0;
              }
              ;
              xianshi_total = xianshi_total + 1;
              //! 附值标题数量
              elel.innerText = xianshi_total + ' / ' + total;
              elel.setAttribute('data-xianshi_total', xianshi_total);
              elel.setAttribute('data-total', total);
              ;
            }

            //# 获取#号面的后缀地址 
            //! 读取储存在本地的表格筛选条件 
            //! 格式["screeningform"][文件相对路径][表格索引][每列标题]
            var list = localStorage.getItem("screeningform");
            try {
              //! 将json数据转换成对象
              list = JSON.parse(list);
            } catch (e) {
              list = {};
            }
            if (list == null) {
              list = {};
            }
            //! 将文件名作为对象键 
            var para = window.top.window.location.hash;
            if (typeof list[para] != "object") {
              list[para] = {};
            }
            //! 将索引值作为对象键
            var index = this.parentNode.parentNode.parentNode.className;
            if (typeof list[para][index] != "object") {
              list[para][index] = {};
            }

            //! 附值内容
            var title = this.innerText;
            //! 将内容去除其它字符串
            title = title.replace(/<\/?.+?\/?>/g, '');
            title = title.replace(/[^\u4e00-\u9fa5^0-9^a-z.]/ig, "");
            title = title.replace(/[\^]/ig, "");
            if (this.getAttribute('table_data_open') == 1) {
              delete list[para][index][title];
            } else {
              list[para][index][title] = 0;
            }
            console.log(list);
            //# 将内容储存在本地
            localStorage.setItem("screeningform", JSON.stringify(list));
          };
        });
        //# 显示标题列
        dom.getElementsByClassName('table_tools')[0].getElementsByClassName('table_num')[0].onclick = function () {
          if (this.style.backgroundColor == 'rgb(230, 230, 230)') {
            this.style.backgroundColor = "rgb(255, 158, 79)";
            this.parentNode.parentNode.getElementsByClassName('table_tools')[0].getElementsByClassName('table_tools_list ')[0].style.display = "block";
          } else {
            this.style.backgroundColor = "rgb(230, 230, 230)";
            this.parentNode.parentNode.getElementsByClassName('table_tools')[0].getElementsByClassName('table_tools_list ')[0].style.display = "none";
          }
        };
      }
    }
  });
}
export default table;