
var drag_id = 0;//当前拖动的ID
function dragsort(e, obj) {
    var id = obj.id;
    var list = obj.list;

    //取出当前选中的value
    for (var i in list) {
        if (list[i].id == id) {
            var value = list[i];
        }
    }
    //传递的ID不存在
    if (!value) {
        return;
    }
    //鼠标按下
    if (e == 'down') {
        drag_id = value.id
        onmousedown(value);
    }
    //鼠标放开
    if (e == 'seup') {
        onmouseup(list);
    }
    //拖动结束
    if (e == 'end') {
        ondragend(list, obj);
    }
    //拖动到有效区域
    if (e == 'enter') {
        ondragenter(value, list, obj, drag_id);
    }
}



function onmouseup(list) {
    for (var i in list) {
        if (list[i].drag == true) {
            list[i].drag = false
        }
    }
}
function ondragend(list, obj) {
    var array_id = [];
    for (var i in list) {
        if (list[i].drag == true) {
            delete list[i].drag
        }
        array_id.push(list[i].id)
    }
    if (typeof obj.callback === "function") {
        obj.callback(array_id)
    }
}
function ondragenter(value, list, obj, drag_id) {
    //# 若放置的元素为拖动的元素，则跳过
    if (value.drag == true) {
        return;
    }


    //@ 重新组合新的对象
    //# 数组
    if (list.constructor == Array) {

        //# 取出当前拖动的对象
        var drag_key = {};
        for (var i in list) {
            if (list[i].drag == true) {
                drag_key = list[i]
                break;
            }
        }
        if (Object.keys(drag_key).length === 0) {
            return;
        }
        var new_list = [];
        var end = true;
        for (var i in list) {
            // 排除本身拖动的对象
            if (list[i].drag == true) {
                end = false;
                continue;
            }

            if (end == true) {
                // 若当前为放置的位置，插入拖动的对象
                if (list[i].id == value.id) {
                    new_list.push(drag_key);
                }
                new_list.push(list[i]);

            } else {
                new_list.push(list[i]);
                // 若当前为放置的位置，插入拖动的对象
                if (list[i].id == value.id) {
                    new_list.push(drag_key);
                }
            }
        }
        if (typeof obj.drag === "function") {
            obj.drag(new_list, drag_id)
        }
        return;
    }
    //# 对象
    if (list.constructor == Object) {
        //# 取出当前拖动的对象
        var drag_key = 0;
        for (var i in list) {
            if (list[i].drag == true) {
                drag_key = i
                break;
            }
        }
        if (drag_key == 0) {
            return;
        }
        var new_list = {};
        var end = true;
        for (var i in list) {
            // 排除本身拖动的对象
            if (list[i].drag == true) {
                end = false;
                continue;
            }

            if (end == true) {
                // 若当前为放置的位置，插入拖动的对象
                if (list[i].id == value.id) {
                    new_list[drag_key] = list[drag_key];
                }
                new_list[i] = list[i];

            } else {
                new_list[i] = list[i];
                // 若当前为放置的位置，插入拖动的对象
                if (list[i].id == value.id) {
                    new_list[drag_key] = list[drag_key];
                }
            }
        }
        if (typeof obj.drag === "function") {
            obj.drag(new_list, drag_id)
        }
    }
}

//鼠标按下
function onmousedown(value) {
    value.drag = true;  //开启拖动
}

export default dragsort;