
import config from '@/gui/vue/config.js';
import get from '@/gui/vue/get.js';
import message from '@/gui/vue/message.js';
import email from '@/gui/vue/email.js';
import imagepaste from '@/gui/vue/imagepaste.js';
import image from '@/gui/vue/image.js';
import previewpicture from '@/gui/vue/previewpicture.js';
import display from '@/gui/vue/display.js';
import date from '@/gui/vue/date.js';
import table from '@/gui/vue/table.js';
import charts from '@/gui/vue/charts.js';
import piechart from '@/gui/vue/piechart.js';
import animate from '@/gui/vue/animate.js';
import socket from '@/gui/vue/socket.js';
import unshift from '@/gui/vue/unshift.js';
import clipboard from '@/gui/vue/clipboard.js';
import lstorage from '@/gui/vue/lstorage.js';
import slice from '@/gui/vue/slice.js';
import input from '@/gui/vue/input.js';
import dragsort from '@/gui/vue/dragsort.js';

export default {
    config: config,
    get: get,
    message: message,
    email: email,
    imagepaste: imagepaste,
    image: image,
    previewpicture: previewpicture,
    display: display,
    date: date,
    table: table,
    charts: charts,
    piechart: piechart,
    animate: animate.animate,
    clearanimate: animate.clearanimate,
    socket: socket,
    unshift: unshift,
    clipboard: clipboard,
    lstorage: lstorage,
    slice: slice,
    input: input,
    dragsort: dragsort
};