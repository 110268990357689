const config = {
    // url: "http://127.0.0.2",     //本地域名
    url: "https://api.yimixixi.com",     //服务端域名



    upload: "https://api.yimixixi.com",  //上传服务器域名
    socket: {
        wss: "ws://app.cha100.com:8018" //socket消息服务器地址
    }
};

export default config;