var socketini; //websocket的实例
var lockReconnect = false; //避免重复连接
var setmun;
//储存回调函数
var success = {};
//重链接次数
var lianmun = 0;

var socket = {};

//@ 连接服务器
socket.ini = function (parem) {


    if (typeof parem == "object" && typeof parem.state == "function" && socketini != null && socketini.readyState == 1) {
        parem.state({ "errsms": "当前已链接", "err": 0 });
        return;
    }

    if (typeof parem == "object" && typeof parem.state == "function") {
        lianmun = lianmun + 1;
        parem.state({ "errsms": "连接中(" + lianmun + ")...", "err": 0 });
    };
    socketini = new WebSocket(parem.wss);
    //链接出错
    socketini.onerror = function (obj, err) {

        reconnect(parem);
    };
    //断开连接
    socketini.onclose = function () {

        reconnect(parem);
    };
    //连接成功
    socketini.onopen = function (e) {
        lianmun = 0;

        //向服务端发送消息
        var obj = {
            type: "login",  //添加类别 
            login: parem.login  //自定义登录信息，JSON格式
        }
        socketini.send(JSON.stringify(obj));
    };


    // 重新链接
    function reconnect(parem) {
        if (lockReconnect) { return; };
        lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {

            if (typeof parem == "object" && typeof parem.state == "function") {
                parem.state({ "errsms": "链接断开，尝试重连...", "err": 0 });
            };
            socket.ini(parem);
            lockReconnect = false;
        }, 2000);
    }
    //心跳检测，防止掉线
    clearInterval(setmun);
    setmun = setInterval(function () {
        if (socketini != null && socketini.readyState == 1) {
            //发送服务器消息
            socketini.send(JSON.stringify({ type: 'ping' }));
        }
    }, 4000);

    //--------------
    //接收到新消息
    //--------------
    socketini.onmessage = function (obj) {

        try {
            //将json数据转换成数组
            var e = JSON.parse(obj.data);
        } catch (err) {
            console.log("解析出现未知错误！");
            console.log(obj);
            return;
        }
        //返回绑定用户信息
        if (typeof parem == "object" && typeof parem.state == "function" && e.type == "login") {

            parem.state(e);
            return;

            //返回私聊消息的回调
        } else if (typeof success.message == "function" && e.type == "message") {
            success.message(e);
            return;

            //收到私聊消息
        } else if (typeof parem == "object" && typeof parem.pussage == "function" && e.type == "pussage") {
            parem.pussage(e);
            return;

        };

    };
    //^^^^^^^^^^^^^^^
};


//@ 私聊：向目标发送私聊消息
socket.message = function (obj, e) {

    if (typeof e == "function" && (socketini == null || socketini.readyState != 1)) {
        e({ "type": "message", "errsms": "当前未连接", "err": 0 });
        return;
    }
    if (typeof e == "function" && (obj.target == null || obj.target == "")) {
        e({ "type": "message", "errsms": "发送内容不能为空1", "err": 0 });
        return;
    }
    if (typeof e == "function" && (obj.suid == null || obj.target.suid == "")) {
        e({ "type": "message", "errsms": "目标的suid不能为空", "err": 0 });
        return;
    }

    //添加类别
    obj.type = "message";
    //将回调附值
    success.message = e;

    //发送服务器消息
    socketini.send(JSON.stringify(obj));


};

socket.delete = function () {
    if (socketini) {
        socketini.close();
    }

}

export default socket;

