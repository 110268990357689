import { createRouter, createWebHashHistory } from 'vue-router'

// 后台框架路由
import routes from '@/gui/admin/db.js'
// 后台目录
import AdminRoutes from '@/admin/AdminRoutes.js'
// web端目录
import WebRoutes from '@/web/WebRoutes.js'


//合并数组
routes = routes.concat(AdminRoutes)
routes = routes.concat(WebRoutes)
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
export default router;