import config from '@/gui/vue/config.js';
import axios from 'axios';
var image = function (e) {
  if (e.event == null || e.event.target == null || e.event.target.parentNode == null) {
    return;
  }

  //选择当前点击的DIV，下面的input子元素
  var btn_lings = e.event.target.parentNode.getElementsByTagName('input');
  console.log(btn_lings);
  var btn = null;
  for (var i in btn_lings) {
    if (i >= 0) {
      if (btn_lings[i].name == "file") {
        btn = btn_lings[i];
      }
    }
  }
  if (btn == null) {
    ElMessageBox.alert("未设置input元素", '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  //点击这个input子元素
  btn.click();

  //# 当input子元素失去焦点时，触发
  btn.onchange = function (event) {
    //得到文本选择框的对象
    var file = event.target.files[0];
    if (file == null) {
      ElMessageBox.alert("选择文件不能为空", '图片上传失败', {
        confirmButtonText: '确认',
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      return;
    }
    if (file.size / 1024 > e.maxsize) {
      ElMessageBox.alert("上传文件不能超过" + e.maxsize + "KB", '图片上传失败', {
        confirmButtonText: '确认',
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      return;
    }

    //打开加载层
    if (e.load == true) {
      //显示加载层
      var loading = ElLoading.service({
        lock: false,
        text: '正在加载中...',
        spinner: '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>',
        background: 'rgba(0, 0, 0, 0)',
        customClass: "guiloading"
      });
    }
    var formData = new FormData(); //创建form对象
    formData.append('file', file); //通过append向form对象添加数据
    formData.append('path', e.path); //上传的目录
    formData.append('allo', JSON.stringify(e.allo)); //可上传文件后缀名  
    axios.post(config.upload + '/guisdk/gui/image', formData, {
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'multipart/form-data'
      }
      //该方法能获取进度，但有跨域报错，未解决
      // onUploadProgress: progressEvent => {
      //     // 原生获取上传进度的事件
      //     if (progressEvent.lengthComputable) {
      //         callback && callback(progressEvent);
      //     }
      // }
      //清求失败  
    }).catch(function (ret) {
      if (e.load == true) {
        //隐藏提示框
        loading.close();
      }
      ElMessageBox.alert(ret.message, '请求超时', {
        confirmButtonText: '确认',
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      return;
    }).then(function (ret) {
      if (e.load == true) {
        //隐藏提示框
        loading.close();
      }
      if (ret == null) {
        return;
      }

      //判断本身是否为对象
      if (typeof ret.data != 'object') {
        ElMessageBox.alert(ret.data, '返回数据错误', {
          confirmButtonText: '确认',
          dangerouslyUseHTMLString: true,
          type: 'error' //success 勾   error叉    warning，info 感叹号
        });

        return;
      }
      if (ret.data.err != 1) {
        ElMessageBox.alert(ret.data.errsms, '图片上传错误', {
          confirmButtonText: '确认',
          dangerouslyUseHTMLString: true,
          type: 'error' //success 勾   error叉    warning，info 感叹号
        });

        return;
      }

      //如果传进来是一个方法的话
      if (typeof e.success === "function") {
        //执行这个方法
        e.success(ret.data);
      }
      return;
    });
  };
};
export default image;