var previewpicture = function (el) {
  if (!el.event) {
    console.log('%c' + ' 方法错误：previewpicture ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n图片元素：', el.event, "未找到");
    ElMessageBox.alert('方法错误previewpicture：未找到图片元素' + el.event, '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (el.event instanceof HTMLElement != true) {
    console.log('%c' + ' 方法错误：previewpicture ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n图片元素：', el.event, "未找到");
    ElMessageBox.alert('方法错误previewpicture：未找到图片元素' + JSON.stringify(el.event), '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }

  //如果已经打开
  if (el.event.getAttribute("data-zoom") == "true") {
    return;
  }
  el.event.setAttribute("data-zoom", true);

  //当前元素位置
  var client = el.event.getBoundingClientRect();

  //创建一个img元素
  var image = document.createElement('img');

  //设置图片连接
  image.src = el.event.src;
  image.style.position = "fixed";
  //获取当前点击的元素 相对窗口距离
  image.style.top = client.y + "px";
  image.style.left = client.x + "px";
  image.style.maxWidth = document.body.clientWidth * 0.5 + "px";
  image.style.maxHeight = document.body.clientHeight * 0.5 + "px";
  image.style.boxShadow = "0px 0px 9px 4px rgba(76,175,80,0.1)";
  image.style.borderRadius = "6px";
  image.style.display = "inherit";
  image.style.zIndex = "100000000";
  image.style.opacity = 0;
  //设置动画
  image.style.transition = "all 0.2s linear";
  //为了设置动画需要获取实际尺寸，先隐藏占位
  image.style.visibility = "hidden";

  //禁止图片被拖动
  image.draggable = false;

  //更改宽度使其动画
  image.style.visibility = "";
  //# 在当前元素后面追加元素
  document.getElementsByTagName('body')[0].append(image);

  //得到尺寸
  var width = image.width;
  var height = image.height;

  //将尺寸设置为0
  image.style.width = "0px";
  image.style.height = "0px";
  setTimeout(function () {
    image.style.width = width + "px";
    image.style.height = height + "px";
    image.style.opacity = 1;
    if (typeof el.access === "function") {
      el.access();
    }
  }, 0);
  var client_left;
  var client_top;
  //# 鼠标按下
  image.addEventListener('mousedown', function (e) {
    //记录被点击时的图片位置
    var shubiao_left = window.event.clientX;
    var shubiao_top = window.event.clientY;

    //获取当前，与窗口距离
    var client_img = e.target.getBoundingClientRect();
    client_left = shubiao_left - client_img.left;
    client_top = shubiao_top - client_img.top;
    //停止动画效果，不然卡
    image.style.transition = "";
  });
  //# 鼠标松开
  image.addEventListener('mouseup', function (e) {
    client_left = null;
    client_top = null;
  });
  //# 绑定鼠标移动事件
  image.addEventListener('mousemove', function (e) {
    if (client_left == null || client_top == null) {
      return;
    }
    // 鼠标的距离
    var shubiao_left = window.event.clientX;
    var shubiao_top = window.event.clientY;
    //重设距离
    image.style.top = shubiao_top - client_top + "px";
    image.style.left = shubiao_left - client_left + "px";
  });

  //# 双击删除元素
  image.addEventListener(el.off, function (e) {
    el.event.setAttribute("data-zoom", false);
    if (typeof el.callback === "function") {
      el.callback();
    }
    //设置动画
    image.style.transition = "all 0.2s linear";
    image.style.width = "0px";
    image.style.height = "0px";
    setTimeout(function () {
      //删除当前元素
      image.remove();
    }, 500);
  });

  //# 鼠标滚动
  image.addEventListener('mousewheel', function (e) {
    //停止动画效果，不然卡
    image.style.transition = "";
    var delta = e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1) ||
    // chrome & ie
    e.detail && (e.detail > 0 ? -1 : 1); // firefox

    // 向上滚
    if (delta > 0) {
      var width = image.width * 1.06;
      var height = image.height * 1.06;

      //鼠标左边距离
      var pageX = window.event.pageX;

      //元素左边距离
      var left = e.target.getBoundingClientRect().left;

      //减少的距离
      left = left - (pageX - left) * 0.06;

      //鼠标左边距离
      var pageY = window.event.pageY;

      //元素左边距离
      var top = e.target.getBoundingClientRect().top;
      //减少的距离
      top = top - (pageY - top) * 0.06;
      image.style.top = top + "px";
      image.style.left = left + "px";
      image.style.width = width + "px";
      image.style.height = height + "px";
      image.style.maxWidth = width + "px";
      image.style.maxHeight = height + "px";

      // 向下滚
    } else if (delta < 0) {
      var width = image.width * 0.94;
      var height = image.height * 0.94;

      //鼠标左边距离
      var pageX = window.event.pageX;

      //元素左边距离
      var left = e.target.getBoundingClientRect().left;

      //减少的距离
      left = left + (pageX - left) * 0.06;

      //鼠标左边距离
      var pageY = window.event.pageY;

      //元素左边距离
      var top = e.target.getBoundingClientRect().top;
      //减少的距离
      top = top + (pageY - top) * 0.06;
      image.style.top = top + "px";
      image.style.left = left + "px";
      image.style.width = width + "px";
      image.style.height = height + "px";
      image.style.maxWidth = width + "px";
      image.style.maxHeight = height + "px";
    }
  });

  // console.log(el.event)

  // return;
  // if (el == null || el == '') {
  //     ElMessageBox.alert('参数不能为空', '方法错误：previewpicture', {
  //         confirmButtonText: '确认',
  //         type: 'error',  //success 勾   error叉    warning，info 感叹号
  //         dangerouslyUseHTMLString: true,   //是否输出html内容
  //     })
  //     return;
  // }

  // if (el.rthis == null) {
  //     ElMessageBox.alert('this不能为空', '方法错误：previewpicture', {
  //         confirmButtonText: '确认',
  //         type: 'error',  //success 勾   error叉    warning，info 感叹号
  //         dangerouslyUseHTMLString: true,   //是否输出html内容
  //     })
  //     return;
  // }
  // if (el.paremid == null || el.paremid == '') {
  //     ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>父元素的CSS类名不能为空', '方法错误：previewpicture', {
  //         confirmButtonText: '确认',
  //         type: 'error',  //success 勾   error叉    warning，info 感叹号
  //         dangerouslyUseHTMLString: true,   //是否输出html内容
  //     })
  //     return;
  // }
  // if (el.class == null || el.class == '') {
  //     ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>子元素的CSS类名不能为空', '方法错误：previewpicture', {
  //         confirmButtonText: '确认',
  //         type: 'error',  //success 勾   error叉    warning，info 感叹号
  //         dangerouslyUseHTMLString: true,   //是否输出html内容
  //     })
  //     return;
  // }
  // if (el.on == null || el.on == '' || el.off == null || el.off == '') {
  //     ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>图片触发方式不能为空', '方法错误：previewpicture', {
  //         confirmButtonText: '确认',
  //         type: 'error',  //success 勾   error叉    warning，info 感叹号
  //         dangerouslyUseHTMLString: true,   //是否输出html内容
  //     })
  //     return;
  // }

  // //下次 DOM 更新循环结束之后执行
  // el.rthis.$nextTick(function () {

  //     //# 判断父元素
  //     var paremid = document.getElementsByClassName(el.paremid);
  //     if (paremid.length > 1) {
  //         ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>父元素：' + el.paremid + "已出现" + paremid.length + "次，请确保唯一。", '方法错误：previewpicture', {
  //             confirmButtonText: '确认',
  //             type: 'error', //success 勾   error叉    warning，info 感叹号
  //             dangerouslyUseHTMLString: true,   //是否输出html内容
  //         })
  //         return;
  //     }
  //     if (paremid.length != 1) {
  //         ElMessageBox.alert('路径：' + el.rthis.$options.__file + '' + '<br>父元素：' + el.paremid + "未找到", '方法错误：previewpicture', {
  //             confirmButtonText: '确认',
  //             type: 'error',  //success 勾   error叉    warning，info 感叹号
  //             dangerouslyUseHTMLString: true,   //是否输出html内容
  //         })
  //         return;
  //     }
  //     //# 判断子元素
  //     var sclass = paremid[0].getElementsByClassName(el.class);
  //     if (sclass.length == 0) {
  //         // ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>子元素：' + el.class + "未找到", '方法错误：previewpicture', {
  //         //     confirmButtonText: '确认',
  //         //     type: 'error', //success 勾   error叉    warning，info 感叹号
  //         //     dangerouslyUseHTMLString: true,   //是否输出html内容
  //         // })
  //         return;
  //     }
  //     //@ 遍历每个子元素
  //     for (var i = 0; i < sclass.length; i++) {

  //     }
  // })
};
// var previewpicture__ = function (el) {

//     if (el == null || el == '') {
//         ElMessageBox.alert('参数不能为空', '方法错误：previewpicture', {
//             confirmButtonText: '确认',
//             type: 'error',  //success 勾   error叉    warning，info 感叹号
//             dangerouslyUseHTMLString: true,   //是否输出html内容
//         })
//         return;
//     }

//     if (el.rthis == null) {
//         ElMessageBox.alert('this不能为空', '方法错误：previewpicture', {
//             confirmButtonText: '确认',
//             type: 'error',  //success 勾   error叉    warning，info 感叹号
//             dangerouslyUseHTMLString: true,   //是否输出html内容
//         })
//         return;
//     }
//     if (el.paremid == null || el.paremid == '') {
//         ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>父元素的CSS类名不能为空', '方法错误：previewpicture', {
//             confirmButtonText: '确认',
//             type: 'error',  //success 勾   error叉    warning，info 感叹号
//             dangerouslyUseHTMLString: true,   //是否输出html内容
//         })
//         return;
//     }
//     if (el.class == null || el.class == '') {
//         ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>子元素的CSS类名不能为空', '方法错误：previewpicture', {
//             confirmButtonText: '确认',
//             type: 'error',  //success 勾   error叉    warning，info 感叹号
//             dangerouslyUseHTMLString: true,   //是否输出html内容
//         })
//         return;
//     }
//     if (el.on == null || el.on == '' || el.off == null || el.off == '') {
//         ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>图片触发方式不能为空', '方法错误：previewpicture', {
//             confirmButtonText: '确认',
//             type: 'error',  //success 勾   error叉    warning，info 感叹号
//             dangerouslyUseHTMLString: true,   //是否输出html内容
//         })
//         return;
//     }

//     //下次 DOM 更新循环结束之后执行
//     el.rthis.$nextTick(function () {

//         //# 判断父元素
//         var paremid = document.getElementsByClassName(el.paremid);
//         if (paremid.length > 1) {
//             ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>父元素：' + el.paremid + "已出现" + paremid.length + "次，请确保唯一。", '方法错误：previewpicture', {
//                 confirmButtonText: '确认',
//                 type: 'error', //success 勾   error叉    warning，info 感叹号
//                 dangerouslyUseHTMLString: true,   //是否输出html内容
//             })
//             return;
//         }
//         if (paremid.length != 1) {
//             ElMessageBox.alert('路径：' + el.rthis.$options.__file + '' + '<br>父元素：' + el.paremid + "未找到", '方法错误：previewpicture', {
//                 confirmButtonText: '确认',
//                 type: 'error',  //success 勾   error叉    warning，info 感叹号
//                 dangerouslyUseHTMLString: true,   //是否输出html内容
//             })
//             return;
//         }
//         //# 判断子元素
//         var sclass = paremid[0].getElementsByClassName(el.class);
//         if (sclass.length == 0) {
//             // ElMessageBox.alert('路径：' + el.rthis.$options.__file + '<br>子元素：' + el.class + "未找到", '方法错误：previewpicture', {
//             //     confirmButtonText: '确认',
//             //     type: 'error', //success 勾   error叉    warning，info 感叹号
//             //     dangerouslyUseHTMLString: true,   //是否输出html内容
//             // })
//             return;
//         }
//         //@ 遍历每个子元素
//         for (var i = 0; i < sclass.length; i++) {
//             //# 可避免重复绑定
//             sclass[i]["on" + el.on] = function (eobj) {

//                 //如果已经打开
//                 if (eobj.target.getAttribute("data-zoom") == "true") {
//                     console.log("跳过")
//                     return;
//                 }

//                 eobj.target.setAttribute("data-zoom", true);

//                 //当前元素位置
//                 var client = eobj.target.getBoundingClientRect();

//                 //创建一个img元素
//                 var image = document.createElement('img')

//                 //设置图片连接
//                 image.src = eobj.target.src;
//                 image.style.position = "fixed";
//                 //获取当前点击的元素 相对窗口距离
//                 image.style.top = client.y + "px";
//                 image.style.left = client.x + "px";
//                 image.style.maxWidth = document.body.clientWidth * 0.5 + "px";
//                 image.style.maxHeight = document.body.clientHeight * 0.5 + "px";
//                 image.style.boxShadow = "0px 0px 9px 4px rgba(76,175,80,0.1)";
//                 image.style.borderRadius = "6px";
//                 image.style.display = "inherit";
//                 image.style.zIndex = "100000000";
//                 image.style.opacity = 0;
//                 //设置动画
//                 image.style.transition = "all 0.2s linear";
//                 //为了设置动画需要获取实际尺寸，先隐藏占位
//                 image.style.visibility = "hidden";

//                 //禁止图片被拖动
//                 image.draggable = false

//                 //更改宽度使其动画
//                 image.style.visibility = "";
//                 //# 在当前元素后面追加元素
//                 document.getElementsByTagName('body')[0].append(image)

//                 //得到尺寸
//                 var width = image.width;
//                 var height = image.height;

//                 //将尺寸设置为0
//                 image.style.width = "0px";
//                 image.style.height = "0px";
//                 setTimeout(function () {

//                     image.style.width = width + "px";
//                     image.style.height = height + "px";
//                     image.style.opacity = 1;

//                     if (typeof el.access === "function") {
//                         el.access();
//                     }

//                 }, 0)

//                 var client_left;
//                 var client_top;
//                 //# 鼠标按下
//                 image.addEventListener('mousedown', function (e) {

//                     //记录被点击时的图片位置
//                     var shubiao_left = window.event.clientX;
//                     var shubiao_top = window.event.clientY;

//                     //获取当前，与窗口距离
//                     var client_img = e.target.getBoundingClientRect();
//                     client_left = shubiao_left - client_img.left;
//                     client_top = shubiao_top - client_img.top;
//                     //停止动画效果，不然卡
//                     image.style.transition = "";
//                 });
//                 //# 鼠标松开
//                 image.addEventListener('mouseup', function (e) {

//                     client_left = null;
//                     client_top = null;
//                 });
//                 //# 绑定鼠标移动事件
//                 image.addEventListener('mousemove', function (e) {
//                     if (client_left == null || client_top == null) {
//                         return;
//                     }
//                     // 鼠标的距离
//                     var shubiao_left = window.event.clientX;
//                     var shubiao_top = window.event.clientY;
//                     //重设距离
//                     image.style.top = shubiao_top - client_top + "px";
//                     image.style.left = shubiao_left - client_left + "px";

//                 })

//                 //# 双击删除元素
//                 image.addEventListener(el.off, function (e) {

//                     eobj.target.setAttribute("data-zoom", false);

//                     if (typeof el.callback === "function") {
//                         el.callback();
//                     }
//                     //设置动画
//                     image.style.transition = "all 0.2s linear";
//                     image.style.width = "0px";
//                     image.style.height = "0px";
//                     setTimeout(function () {

//                         //删除当前元素
//                         image.remove();

//                     }, 500)

//                 })

//                 //# 鼠标滚动
//                 image.addEventListener('mousewheel', function (e) {
//                     //停止动画效果，不然卡
//                     image.style.transition = "";

//                     var delta = (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1)) ||  // chrome & ie
//                         (e.detail && (e.detail > 0 ? -1 : 1));              // firefox

//                     // 向上滚
//                     if (delta > 0) {

//                         var width = image.width * 1.06;
//                         var height = image.height * 1.06;

//                         //鼠标左边距离
//                         var pageX = window.event.pageX;

//                         //元素左边距离
//                         var left = e.target.getBoundingClientRect().left;

//                         //减少的距离
//                         left = left - (pageX - left) * 0.06;

//                         //鼠标左边距离
//                         var pageY = window.event.pageY;

//                         //元素左边距离
//                         var top = e.target.getBoundingClientRect().top;
//                         //减少的距离
//                         top = top - (pageY - top) * 0.06;

//                         image.style.top = top + "px";
//                         image.style.left = left + "px";
//                         image.style.width = width + "px";
//                         image.style.height = height + "px";
//                         image.style.maxWidth = width + "px";
//                         image.style.maxHeight = height + "px";

//                         // 向下滚
//                     } else if (delta < 0) {
//                         var width = image.width * 0.94;
//                         var height = image.height * 0.94;

//                         //鼠标左边距离
//                         var pageX = window.event.pageX;

//                         //元素左边距离
//                         var left = e.target.getBoundingClientRect().left;

//                         //减少的距离
//                         left = left + (pageX - left) * 0.06;

//                         //鼠标左边距离
//                         var pageY = window.event.pageY;

//                         //元素左边距离
//                         var top = e.target.getBoundingClientRect().top;
//                         //减少的距离
//                         top = top + (pageY - top) * 0.06;

//                         image.style.top = top + "px";
//                         image.style.left = left + "px";
//                         image.style.width = width + "px";
//                         image.style.height = height + "px";
//                         image.style.maxWidth = width + "px";
//                         image.style.maxHeight = height + "px";

//                     }

//                 })

//             };

//         }
//     })

// }
export default previewpicture;