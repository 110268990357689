
var WebRoutes = [
    {
        // 首页
        path: '/index',
        component: () => import('@/web/shouye/children/index.vue')

    },
    {
        // 关于我们
        path: '/about',
        component: () => import('@/web/shouye/children/about.vue')

    },
    {
        // 联系我们
        path: '/contact',
        component: () => import('@/web/shouye/children/contact.vue')

    }


];
export default WebRoutes;