function clipboard(el) {
  if (!el || !el.text) {
    ElMessageBox.alert('复制的内容不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (typeof ClipboardJS == "undefined" || ClipboardJS == null || !ClipboardJS) {
    ElMessageBox.alert('需通过标签引入clipboard.js文件', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  //执行复制
  ClipboardJS.copy(el.text);
  //回调到前端
  if (typeof el.success === "function") {
    el.success();
  }
  ;
}
export default clipboard;