import config from '@/gui/vue/config.js';
import axios from 'axios';
import md5 from '@/gui/vue/sdk/md5.js';

//倒计时的ID
var daojishiID = 0;
//--------------------------------
//向指定手机号发送短信
//el.id  输入手机号码的元素id
//el.tpl  聚合短信模板id
var email = function (el) {
  if (el == null || el == "") {
    ElMessageBox.alert('参数不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  } else if (el.email == null || el.email == "" || el.text == null || el.text == "" || el.title == null || el.title == "") {
    ElMessageBox.alert('邮件信息不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  ;
  el.key = md5("wgj" + config.url);
  if (typeof el.countdown === "function") {
    el.countdown("发送中...");
  }
  axios.post(config.url + '/guisdk/Email/email', {
    'email': el.email,
    'text': el.text,
    'title': el.title,
    "token": localStorage.getItem("token")
  }, {
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
    //清求失败  
  }).catch(function (ret) {
    ElMessageBox.alert(ret.msg, el.url + '请求超时', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }).then(function (ret) {
    if (ret.statusCode == 404) {
      ElMessageBox.alert(ret.msg, el.url + '请求超时', {
        confirmButtonText: '确认',
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      return;
    }
    //将json数据转换成数组
    var err = ret.data;
    if (typeof err != 'object') {
      ElMessageBox.alert(err, '请求数据错误', {
        confirmButtonText: '确认',
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      geterror("返回数据格式错误\n" + iurl + "\n" + err);
      return;
    }
    if (err.err == 1) {
      if (typeof el.countdown === "function") {
        el.countdown("发送成功");
      }
    }

    //如果传进来是一个方法的话
    if (typeof el.success === "function") {
      //执行这个方法
      el.success(err);
    }
    if (err.time > 0) {
      if (daojishiID > 0) {
        //停止循环
        clearInterval(daojishiID);
      }

      //js倒计时执行，延迟执行，循环执行
      daojishiID = setInterval(function () {
        err.time -= 1;

        //分钟
        var minute = parseInt(err.time / 60);
        //秒
        var second = err.time - parseInt(err.time / 60) * 60;
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (second < 10) {
          second = "0" + second;
        }
        if (err.time <= 0) {
          if (typeof el.countdown === "function") {
            el.countdown("发送验证码");
          }
          //停止循环
          clearInterval(daojishiID);
          return;
        }
        if (typeof el.countdown === "function") {
          el.countdown(minute + ":" + second);
        }
      }, 1000);
    } else {
      el.countdown("发送验证码");
    }
    return;
  });
};
export default email;