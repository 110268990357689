var myreq;
function animate(obj) {

    if (!obj.time || obj.time == "") {
        obj.time = 0;
    }

    obj.num = 0;
    var i = 1;
    function fun() {

        //设置时间小于20ms，则不延迟
        if (i == 1 || obj.time < 20) {

            obj.num += 1;
            obj.callback(obj);

            //般按照60Hz算，每秒执行60次
            // 计数器 % (60/一秒钟执行的次数)
        } else if (i % parseInt(60 / (1000 / obj.time)) == 0) {
            obj.num += 1;

            obj.callback(obj);
        }
        i++;
        //限制执行次数
        if (obj.max > 0 && obj.max <= obj.num) {
            // console.log("超过执行次数")
            return;
        }
        //暂停执行
        if (obj.end == true) {
            // console.log("暂停执行")
            return;
        }
        //再次执行
        myreq = window.requestAnimationFrame(fun);

    }
    //requestAnimationFrame 请求动画帧，是html新出的特性API可以用来实现一些动画效果，目前主流浏览器都已经得到支持了
    //返回值是当前执行的唯一标志，用来清除这次执行（与计时器类似）
    myreq = window.requestAnimationFrame(fun);

};


function clearanimate() {
    // console.log("手动停止")
    // 主动控制清除动画
    cancelAnimationFrame(myreq);
}

export default { animate, clearanimate };