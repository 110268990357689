import animate from '@/gui/vue/animate.js';
var display = function (el) {
  if (!el.event.parentNode) {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n子元素：', el.event, "未找到");
    ElMessageBox.alert('方法错误display：触发的元素不存在', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  var chilren = el.event.parentNode.getElementsByClassName(el.class)[0];
  if (!chilren) {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n未找到折叠元素：', el.class, "未找到");
    ElMessageBox.alert('方法错误display：未找到折叠元素' + el.class, '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }

  //# 获取原始值
  var offsetHeight = 0;
  var paddingtop = 0;
  var paddingbottom = 0;
  var bordertop = 0;
  var borderbottom = 0;

  //判断是否存在原始值
  var datasize = chilren.getAttribute("data-size");
  datasize = JSON.parse(datasize);
  var show = "";
  if (datasize == null) {
    if (chilren.style.display == "none") {
      show = "none";
    }

    //获取元素的最终样式
    var style = window.getComputedStyle(chilren, null);
    //取出上边距离
    paddingtop = parseFloat(style.getPropertyValue('padding-top'));
    paddingbottom = parseFloat(style.getPropertyValue('padding-bottom'));
    //取取上下描边宽度
    bordertop = parseFloat(style.getPropertyValue('border-top-width'));
    borderbottom = parseFloat(style.getPropertyValue('border-bottom-width'));

    //保存之前高度
    var lssheight = chilren.style.height;
    chilren.style.visibility = "hidden"; //隐藏并占位
    chilren.style.display = "block"; //关闭none属性
    chilren.style.height = "auto"; //高度设置自动

    //清空上下边距
    chilren.style.paddingTop = "";
    chilren.style.paddingBottom = "";
    //清空上下描边宽度
    chilren.style.borderTopWidth = "";
    chilren.style.borderBottomWidth = "";

    //得到高度
    var offsetHeight = chilren.offsetHeight;
    datasize = {
      offsetHeight: offsetHeight,
      paddingtop: paddingtop,
      paddingbottom: paddingbottom,
      bordertop: bordertop,
      borderbottom: borderbottom
    };
    //设置属性值
    chilren.setAttribute("data-size", JSON.stringify(datasize));

    //还原
    chilren.style.visibility = ""; //隐藏并占位
    chilren.style.display = ""; //关闭none属性
    chilren.style.height = lssheight; //高度设置自动
  }

  chilren.style.overflow = "hidden"; //超出隐藏

  offsetHeight = datasize.offsetHeight;
  paddingtop = datasize.paddingtop;
  paddingbottom = datasize.paddingbottom;
  bordertop = datasize.bordertop;
  borderbottom = datasize.borderbottom;

  //@ 折叠
  if (chilren.offsetHeight > 0 && show != "none") {
    //# 开始计算新的值
    //原始尺寸 - 描边宽度 - 内边距宽度
    offsetHeight = offsetHeight - paddingtop - paddingbottom - bordertop - borderbottom;

    // 计算每次展开的高度
    var heitop = offsetHeight / (el.time / 6.90);
    var paddtop = paddingtop / (el.time / 6.90);
    var paddbottom = paddingbottom / (el.time / 6.90);
    var bortop = bordertop / (el.time / 6.90);
    var borbottom = borderbottom / (el.time / 6.90);
    var lsopacity = 1 / (el.time / 6.90);
    var timestamp = Math.round(new Date());
    var opacity = 1;
    callinSoundoff_time(offsetHeight);
    //递归不断执行
    function callinSoundoff_time(offsetHeight) {
      //# 每次高度减一
      //上描边宽度
      bordertop = bordertop - bortop;
      //下描边宽度
      borderbottom = borderbottom - borbottom;

      //上内边距
      paddingtop = paddingtop - paddtop;
      //下内边距
      paddingbottom = paddingbottom - paddbottom;

      //透明度
      opacity = opacity - lsopacity;

      //高度
      offsetHeight = offsetHeight - heitop;
      if (offsetHeight < 0) {
        offsetHeight = 0;
        bordertop = 0;
        borderbottom = 0;
        paddingtop = 0;
        paddingbottom = 0;
        opacity = 0;
      }

      //# 设置新的高度
      chilren.style.height = offsetHeight + "px";
      chilren.style.borderTopWidth = bordertop + "px";
      chilren.style.borderBottomWidth = borderbottom + "px";
      chilren.style.paddingTop = paddingtop + "px";
      chilren.style.paddingBottom = paddingbottom + "px";
      chilren.style.opacity = opacity;

      //若高度小于0则停止
      if (offsetHeight == 0) {
        //# 折叠结束
        if (typeof el.end === "function") {
          chilren.style.height = "0px";
          chilren.style.borderTopWidth = "0px";
          chilren.style.borderBottomWidth = "0px";
          chilren.style.paddingTop = "0px";
          chilren.style.paddingBottom = "0px";

          // 返回位置坐标
          el.end({
            time: Math.round(new Date()) - timestamp,
            class: el.event,
            event: chilren,
            height: offsetHeight,
            show: false,
            errsms: "折叠结束"
          });
        }
        return;
      }
      //延迟执行
      animate.animate({
        time: 100,
        //延迟时间
        max: 1,
        //执行次数，为空则无限次
        callback: function (obj) {
          callinSoundoff_time(offsetHeight);
        }
      });
    }
    return;
  } else {
    //@ 展开

    chilren.style.display = "block"; //关闭none属性
    //# 开始计算新的值
    //原始尺寸 - 描边宽度 - 内边距宽度
    offsetHeight = offsetHeight - paddingtop - paddingbottom - bordertop - borderbottom;

    // 计算每次展开的高度
    var heitop = offsetHeight / (el.time / 6.90);
    var paddtop = paddingtop / (el.time / 6.90);
    var paddbottom = paddingbottom / (el.time / 6.90);
    var bortop = bordertop / (el.time / 6.90);
    var borbottom = borderbottom / (el.time / 6.90);
    var opacity = 1 / (el.time / 6.90);
    var timestamp = Math.round(new Date());
    var lsbordertop = 0;
    var lsborderbottom = 0;
    var lspaddingtop = 0;
    var lspaddingbottom = 0;
    var lsoffsetHeight = 0;
    var lsopacity = 0;
    callinSoundon_time(lsoffsetHeight);
    //递归不断执行
    function callinSoundon_time(lsoffsetHeight) {
      //# 每次高度减一
      //上描边宽度
      lsbordertop = lsbordertop + bortop;
      //下描边宽度
      lsborderbottom = lsborderbottom + borbottom;

      //上内边距
      lspaddingtop = lspaddingtop + paddtop;
      //下内边距
      lspaddingbottom = lspaddingbottom + paddbottom;

      //透明度
      lsopacity = lsopacity + opacity;

      //高度
      lsoffsetHeight = lsoffsetHeight + heitop;
      if (lsoffsetHeight >= offsetHeight) {
        //# 设置新的高度

        chilren.style.height = "";
        chilren.style.borderTopWidth = "";
        chilren.style.borderBottomWidth = "";
        chilren.style.paddingTop = "";
        chilren.style.paddingBottom + "";
        chilren.style.opacity = 1;
      } else {
        //# 设置新的高度
        chilren.style.height = lsoffsetHeight + "px";
        chilren.style.borderTopWidth = lsbordertop + "px";
        chilren.style.borderBottomWidth = lsborderbottom + "px";
        chilren.style.paddingTop = lspaddingtop + "px";
        chilren.style.paddingBottom = lspaddingbottom + "px";
        chilren.style.opacity = lsopacity;
      }

      //若高度小于0则停止
      if (lsoffsetHeight >= offsetHeight) {
        chilren.style.overflow = "";
        //# 展开结束
        if (typeof el.start == "function") {
          // 返回位置坐标
          el.start({
            class: el.event,
            event: chilren,
            time: Math.round(new Date()) - timestamp,
            height: lsoffsetHeight,
            show: true,
            errsms: "展开结束"
          });
        }
        return;
      }
      //延迟执行
      animate.animate({
        time: 100,
        //延迟时间
        max: 1,
        //执行次数，为空则无限次
        callback: function (obj) {
          callinSoundon_time(lsoffsetHeight);
        }
      });
    }
    return;
  }
  ;
  return;
  // .parentNode.children;

  //# 判断子元素
  var sclass = el.event.getElementsByClassName(el.class);
  if (sclass.length == 0) {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n子元素：' + el.class + "未找到", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  return;
  if (el == null || el == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n参数不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.rthis == null) {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n this不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.paremid == null || el.paremid == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n父元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.class == null || el.class == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n子元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.event == null || el.event == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n折叠元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.time == '' || el.time == null) {
    el.time = 300;
  }

  //下次 DOM 更新循环结束之后执行
  el.rthis.$nextTick(function () {
    //# 判断父元素
    var paremid = document.getElementsByClassName(el.paremid);
    if (paremid.length > 1) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n父元素：' + el.paremid + "已出现" + paremid.length + "次，请确保唯一。", '\n路径：' + el.rthis.$options.__file);
      return;
    }
    if (paremid.length != 1) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n父元素：' + el.paremid + "未找到", '\n路径：' + el.rthis.$options.__file);
      return;
    }

    //# 判断子元素
    var sclass = paremid[0].getElementsByClassName(el.class);
    if (sclass.length == 0) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n子元素：' + el.class + "未找到", '\n路径：' + el.rthis.$options.__file);
      return;
    }

    //@ 遍历每个子元素
    var chilren;
    var eobj;
    for (var i = 0; i < sclass.length; i++) {}
    ;
  });
};
var display__ = function (el) {
  if (el == null || el == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n参数不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.rthis == null) {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n this不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.paremid == null || el.paremid == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n父元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.class == null || el.class == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n子元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.event == null || el.event == '') {
    console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', "\n折叠元素的CSS类名不能为空", '\n路径：' + el.rthis.$options.__file);
    return;
  }
  if (el.time == '' || el.time == null) {
    el.time = 300;
  }

  //下次 DOM 更新循环结束之后执行
  el.rthis.$nextTick(function () {
    //# 判断父元素
    var paremid = document.getElementsByClassName(el.paremid);
    if (paremid.length > 1) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n父元素：' + el.paremid + "已出现" + paremid.length + "次，请确保唯一。", '\n路径：' + el.rthis.$options.__file);
      return;
    }
    if (paremid.length != 1) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n父元素：' + el.paremid + "未找到", '\n路径：' + el.rthis.$options.__file);
      return;
    }

    //# 判断子元素
    var sclass = paremid[0].getElementsByClassName(el.class);
    if (sclass.length == 0) {
      console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n子元素：' + el.class + "未找到", '\n路径：' + el.rthis.$options.__file);
      return;
    }

    //@ 遍历每个子元素
    var chilren;
    var eobj;
    for (var i = 0; i < sclass.length; i++) {
      //# 可避免重复绑定
      sclass[i].onclick = function (e) {
        //寻找父节点，在取所有子节点
        eobj = e.target.parentNode.children;

        //折叠与展示的元素class，需与点击除法的class同级
        var lsevent = false;
        for (var ii = 0; ii < eobj.length; ii++) {
          if (eobj[ii].classList.contains(el.event) == true) {
            lsevent = true;
            //取当前的节点
            chilren = eobj[ii];
            break;
          }
        }
        if (lsevent == false) {
          console.log('%c' + ' 方法错误：display ', 'color: #fff; background: #3c9cff;  border-radius: 5px;', '\n折叠元素：' + el.event + "未找到", '\n路径：' + el.rthis.$options.__file);
          return;
        }

        //# 获取原始值
        var offsetHeight = 0;
        var paddingtop = 0;
        var paddingbottom = 0;
        var bordertop = 0;
        var borderbottom = 0;

        //判断是否存在原始值
        var datasize = chilren.getAttribute("data-size");
        datasize = JSON.parse(datasize);
        var show = "";
        if (datasize == null) {
          if (chilren.style.display == "none") {
            show = "none";
          }

          //获取元素的最终样式
          var style = window.getComputedStyle(chilren, null);
          //取出上边距离
          paddingtop = parseFloat(style.getPropertyValue('padding-top'));
          paddingbottom = parseFloat(style.getPropertyValue('padding-bottom'));
          //取取上下描边宽度
          bordertop = parseFloat(style.getPropertyValue('border-top-width'));
          borderbottom = parseFloat(style.getPropertyValue('border-bottom-width'));

          //保存之前高度
          var lssheight = chilren.style.height;
          chilren.style.visibility = "hidden"; //隐藏并占位
          chilren.style.display = "block"; //关闭none属性
          chilren.style.height = "auto"; //高度设置自动

          //清空上下边距
          chilren.style.paddingTop = "";
          chilren.style.paddingBottom = "";
          //清空上下描边宽度
          chilren.style.borderTopWidth = "";
          chilren.style.borderBottomWidth = "";

          //得到高度
          var offsetHeight = chilren.offsetHeight;
          datasize = {
            offsetHeight: offsetHeight,
            paddingtop: paddingtop,
            paddingbottom: paddingbottom,
            bordertop: bordertop,
            borderbottom: borderbottom
          };
          //设置属性值
          chilren.setAttribute("data-size", JSON.stringify(datasize));

          //还原
          chilren.style.visibility = ""; //隐藏并占位
          chilren.style.display = ""; //关闭none属性
          chilren.style.height = lssheight; //高度设置自动
        }

        chilren.style.overflow = "hidden"; //超出隐藏

        offsetHeight = datasize.offsetHeight;
        paddingtop = datasize.paddingtop;
        paddingbottom = datasize.paddingbottom;
        bordertop = datasize.bordertop;
        borderbottom = datasize.borderbottom;

        //@ 折叠
        if (chilren.offsetHeight > 0 && show != "none") {
          //# 开始计算新的值
          //原始尺寸 - 描边宽度 - 内边距宽度
          offsetHeight = offsetHeight - paddingtop - paddingbottom - bordertop - borderbottom;

          // 计算每次展开的高度
          var heitop = offsetHeight / (el.time / 4.90);
          var paddtop = paddingtop / (el.time / 4.90);
          var paddbottom = paddingbottom / (el.time / 4.90);
          var bortop = bordertop / (el.time / 4.90);
          var borbottom = borderbottom / (el.time / 4.90);
          var lsopacity = 1 / (el.time / 4.90);
          var timestamp = Math.round(new Date());
          var opacity = 1;
          callinSoundoff_time(offsetHeight);
          //递归不断执行
          function callinSoundoff_time(offsetHeight) {
            //# 每次高度减一
            //上描边宽度
            bordertop = bordertop - bortop;
            //下描边宽度
            borderbottom = borderbottom - borbottom;

            //上内边距
            paddingtop = paddingtop - paddtop;
            //下内边距
            paddingbottom = paddingbottom - paddbottom;

            //透明度
            opacity = opacity - lsopacity;

            //高度
            offsetHeight = offsetHeight - heitop;
            if (offsetHeight < 0) {
              offsetHeight = 0;
              bordertop = 0;
              borderbottom = 0;
              paddingtop = 0;
              paddingbottom = 0;
              opacity = 0;
            }

            //# 设置新的高度
            chilren.style.height = offsetHeight + "px";
            chilren.style.borderTopWidth = bordertop + "px";
            chilren.style.borderBottomWidth = borderbottom + "px";
            chilren.style.paddingTop = paddingtop + "px";
            chilren.style.paddingBottom = paddingbottom + "px";
            chilren.style.opacity = opacity;

            //若高度小于0则停止
            if (offsetHeight == 0) {
              //# 折叠结束
              if (typeof el.end === "function") {
                chilren.style.height = "0px";
                chilren.style.borderTopWidth = "0px";
                chilren.style.borderBottomWidth = "0px";
                chilren.style.paddingTop = "0px";
                chilren.style.paddingBottom = "0px";

                // 返回位置坐标
                el.end({
                  time: Math.round(new Date()) - timestamp,
                  class: e.target,
                  event: chilren,
                  height: offsetHeight,
                  show: false,
                  errsms: "折叠结束"
                });
              }
              return;
            }
            //延迟执行
            animate.animate({
              time: 100,
              //延迟时间
              max: 1,
              //执行次数，为空则无限次
              callback: function (obj) {
                callinSoundoff_time(offsetHeight);
              }
            });
            // setTimeout(function () {
            //     callinSoundoff_time(offsetHeight);
            // }, 0);
          }

          return;
        } else {
          //@ 展开

          chilren.style.display = "block"; //关闭none属性
          //# 开始计算新的值
          //原始尺寸 - 描边宽度 - 内边距宽度
          offsetHeight = offsetHeight - paddingtop - paddingbottom - bordertop - borderbottom;

          // 计算每次展开的高度
          var heitop = offsetHeight / (el.time / 4.90);
          var paddtop = paddingtop / (el.time / 4.90);
          var paddbottom = paddingbottom / (el.time / 4.90);
          var bortop = bordertop / (el.time / 4.90);
          var borbottom = borderbottom / (el.time / 4.90);
          var opacity = 1 / (el.time / 4.90);
          var timestamp = Math.round(new Date());
          var lsbordertop = 0;
          var lsborderbottom = 0;
          var lspaddingtop = 0;
          var lspaddingbottom = 0;
          var lsoffsetHeight = 0;
          var lsopacity = 0;
          callinSoundon_time(lsoffsetHeight);
          //递归不断执行
          function callinSoundon_time(lsoffsetHeight) {
            //# 每次高度减一
            //上描边宽度
            lsbordertop = lsbordertop + bortop;
            //下描边宽度
            lsborderbottom = lsborderbottom + borbottom;

            //上内边距
            lspaddingtop = lspaddingtop + paddtop;
            //下内边距
            lspaddingbottom = lspaddingbottom + paddbottom;

            //透明度
            lsopacity = lsopacity + opacity;

            //高度
            lsoffsetHeight = lsoffsetHeight + heitop;
            if (lsoffsetHeight >= offsetHeight) {
              //# 设置新的高度

              chilren.style.height = "";
              chilren.style.borderTopWidth = "";
              chilren.style.borderBottomWidth = "";
              chilren.style.paddingTop = "";
              chilren.style.paddingBottom + "";
              chilren.style.opacity = 1;
            } else {
              //# 设置新的高度
              chilren.style.height = lsoffsetHeight + "px";
              chilren.style.borderTopWidth = lsbordertop + "px";
              chilren.style.borderBottomWidth = lsborderbottom + "px";
              chilren.style.paddingTop = lspaddingtop + "px";
              chilren.style.paddingBottom = lspaddingbottom + "px";
              chilren.style.opacity = lsopacity;
            }

            //若高度小于0则停止
            if (lsoffsetHeight >= offsetHeight) {
              //# 展开结束
              if (typeof el.start == "function") {
                // 返回位置坐标
                el.start({
                  class: e.target,
                  event: chilren,
                  time: Math.round(new Date()) - timestamp,
                  height: lsoffsetHeight,
                  show: true,
                  errsms: "展开结束"
                });
              }
              return;
            }
            //延迟执行
            animate.animate({
              time: 100,
              //延迟时间
              max: 1,
              //执行次数，为空则无限次
              callback: function (obj) {
                callinSoundon_time(lsoffsetHeight);
              }
            });
          }
          return;
        }
        ;
      };
    }
    ;
  });
};
export default display;