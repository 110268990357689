import "core-js/modules/es.array.push.js";
import echarts from '@/gui/vue/sdk/echarts.min.js';
function charts(list, obj) {
  if (list == null || list == "") {
    ElMessageBox.alert('数据不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj == null || obj == "") {
    ElMessageBox.alert('参数不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj.dom == null || obj.dom == "") {
    ElMessageBox.alert('dom元素不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  var dom = document.getElementsByClassName(obj.dom);
  if (dom.length == 0) {
    ElMessageBox.alert('元素class：' + obj.dom + '不存在', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (dom.length > 1) {
    ElMessageBox.alert('元素class：' + obj.dom + '存在多个', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj.style.width == null) {
    obj.style.width = 800;
  }
  if (obj.style.height == null) {
    obj.style.height = 300;
  }
  //设置尺寸
  dom[0].style.width = obj.style.width + "px";
  dom[0].style.height = obj.style.height + "px";

  //配置默认颜色
  var oncolor = ["#f44336", "#9c27b0", "#3f51b5", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#cddc39", "#ffeb3b", "#ff9800", "#ff5722", "#795548", "#9e9e9e", "#607d8b"];

  // 基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(dom[0]);

  //@ 设置边距离
  obj.style.left = parseInt(obj.style.left);
  if (obj.style.left < 0) {
    obj.style.left = 0;
  }
  obj.style.right = parseInt(obj.style.right);
  if (obj.style.right < 0) {
    obj.style.right = 0;
  }
  obj.style.top = parseInt(obj.style.top);
  if (obj.style.top < 0) {
    obj.style.top = 0;
  }
  obj.style.bottom = parseInt(obj.style.bottom);
  if (obj.style.bottom < 0) {
    obj.style.bottom = 0;
  }
  obj.style.left += 40; //左边距离
  obj.style.right += 20; //右边距离
  obj.style.top += 40; //顶边距离
  obj.style.bottom += 30; //下边距离

  //@ 验证多条数据键名和长度是否一致
  var keysnull = '';
  var keys = '';
  for (var i in list) {
    keys = '';
    for (var ii in list[i].data) {
      keys = keys + ii + ',';
    }
    if (keysnull == '') {
      keysnull = keys;
    }
    if (keysnull != keys) {
      ElMessageBox.alert('数据长度或键名不一致<br>' + keysnull + "<br>" + keys, '提示信息', {
        confirmButtonText: '确认',
        type: 'error',
        //success 勾   error叉    warning，info 感叹号
        dangerouslyUseHTMLString: true //是否输出html内容
      });

      return;
    }
  }

  //@ 取出数据分类
  var fenlei = {};
  var text = '';
  for (var i in list) {
    //type 分类的标题
    if (list[i].type != '' && list[i].type != null) {
      text = list[i].type;
    } else {
      text = "未分类";
    }
    //text 分类名称
    fenlei[text] = true;
  }
  //重新建索引
  var index = 0;
  for (var i in fenlei) {
    fenlei[i] = index;
    index += 1;
  }

  //@ 设置标题
  var legend = [];
  var show;
  for (var i in list) {
    //取出分类的索引
    index = 0;
    if (list[i].type != '' && list[i].type != null && fenlei[list[i].type] > 0) {
      index = fenlei[list[i].type];
    }
    //默认只显示第一个分类
    if (index == 0) {
      show = true;
    } else {
      show = false;
    }
    if (!obj.series) {
      obj.series = [];
    }
    obj.series[i] = list[i].series;
    if (list[i].series == null) {
      list[i].series = {
        show: true,
        //显示线转角圆点，false不显示
        selected: true,
        //显示该行数据，flase不显示
        color: oncolor[i] //线条颜色
      };
    }

    if (legend[index] == null) {
      //# 添加标题属性
      legend[index] = {
        "data": [],
        //顶部分类标题
        "selected": {},
        //是否显示该行数据
        "align": "left",
        "show": show //隐藏该行
      };
    }

    //# 添加标题
    if (obj.atte.legend != false) {
      //换行显示
      if (list[i].series && list[i].series.line == true) {
        legend[index].data.push({
          "name": ""
        });
      }
      legend[index].data.push({
        "name": list[i].name //标题
      });
    }

    //# 显示该行
    // selected  为false时默认不显示该行
    if (list[i].series && list[i].series.selected != false) {
      legend[index].selected[list[i].name] = true;
    } else {
      legend[index].selected[list[i].name] = false;
    }
  }
  //@ 设置数据
  var xAxis_data = [];
  var color = [];
  var series = [];
  var data = [];
  var bar;
  var width;
  for (var i in list) {
    //# 取出数据
    data = [];
    xAxis_data = [];
    for (var ii in list[i].data) {
      data.push(list[i].data[ii]); //线条数据
      xAxis_data.push(ii); //每标数据的标题
    }
    //# 颜色
    if (list[i].series.color != '') {
      color.push(list[i].series.color);
    }
    //# line折线统计图 或 bar柱状图  
    bar = "line";
    if (list[i].series.type == "bar") {
      bar = "bar";
    }

    //# line折线统计图 或 bar柱状图  
    width = 2;
    if (list[i].series.width > 0) {
      width = list[i].series.width;
    }
    series.push({
      "data": data,
      //线条数据
      "lineStyle": {
        // width:width,     //线宽
        // type:style,  //线的类型
      },
      "itemStyle": {
        // borderWidth:0, //描边线宽。为 0 时无描边
        // borderColor:"#f00",
      },
      "name": list[i].name,
      //分类名称
      "showSymbol": list[i].series.show,
      // 线上面不显示圆点
      "smooth": true,
      //折线平滑
      "type": bar,
      //line折线统计图 或 bar柱状图  
      "emphasis": {
        // 鼠标移上去的时候，拐点的颜色和样式
        "itemStyle": {
          "borderWidth": 1 // 描边的线宽
        },

        "scale": true,
        //是否开启 hover 在拐点标志上的放大效果。
        "focus": "series" //在高亮图形时，是否淡出其它数据的图形已达到聚焦的效果
      },

      "barWidth": width,
      //如果是柱状图，则传递宽度
      "yAxisIndex": 0 //显示Y轴
    });
  }

  //@ 显示范围
  if (parseInt(obj.atte.maxvalue) > 0) {
    var dataZoom = {
      //缩放的开始位置的数值，只有在工具栏的缩放行为的事件中存在。
      startValue: 0,
      //缩放的结束位置的数值，只有在工具栏的缩放行为的事件中存在。
      endValue: parseInt(obj.atte.maxvalue) - 1,
      type: 'inside' //滚轮缩放
    };
  } else {
    var dataZoom = {
      start: 0,
      //默认左边位置
      end: 100,
      //默认右边位置
      type: 'inside' //滚轮缩放
    };
  }

  //@ 设置属性
  var option = {
    "color": color,
    "tooltip": {
      "extraCssText": "box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);",
      "backgroundColor": "rgba(255,255,255,0.8)",
      "trigger": "axis",
      "axisPointer": {
        "type": "cross"
      },
      "textStyle": {
        "fontSize": 12
      },
      //设置echarts中的formatter参数
      formatter: function (a) {
        var formlist = [];
        var itmes = "";
        var backgrund = "";
        var unit = '';
        var type = '';
        for (var i = 0; i < a.length; i++) {
          //# 取出单位和分类
          unit = '';
          type = '';
          for (var ii in list) {
            if (a[i].seriesName == list[ii].name) {
              unit = list[ii].unit;
              type = list[ii].type;
            }
          }
          if (i > 0) {
            itmes = "";
          } else {
            itmes = a[i].name + "<br>";
          }
          itmes = itmes + '<i style="display:inline-block;width:10px;height:10px;';
          itmes = itmes + 'background:' + a[i].color + ';margin-right:5px;border-radius:50%;"></i>';
          if (document.getElementsByClassName(obj.dom)[0].getAttribute("data-column") == a[i].componentIndex) {
            backgrund = "style='background-color:" + a[i].color + ";color: #fff;padding: 0px 8px;border-radius: 10px;'";
          } else {
            backgrund = "";
          }
          if (type != '' && type != null) {
            itmes = itmes + "<span " + backgrund + " data-index='" + i + "'>" + type + "：" + a[i].seriesName + "：" + Number(a[i].value).toLocaleString() + " " + unit + "</span > ";
          } else {
            itmes = itmes + "<span " + backgrund + " data-index='" + i + "'>" + a[i].seriesName + "：" + Number(a[i].value).toLocaleString() + " " + unit + "</span > ";
          }
          formlist.push(itmes);
        }
        return formlist.join("<br>");
      }
    },
    "legend": legend,
    "dataZoom": [dataZoom],
    "grid": {
      left: obj.style.left,
      //左边距离
      right: obj.style.right,
      //右边距离
      top: obj.style.top,
      //顶边距离
      bottom: obj.style.bottom //下边距离
    },

    "xAxis": {
      "type": "category",
      "data": xAxis_data //每标数据的标题
    },

    "yAxis": [{
      "type": "value",
      "position": "left",
      "splitLine": {
        "lineStyle": {
          "type": "dashed",
          "width": 1,
          "color": "#eeeeee"
        }
      }
    }],
    "series": series
  };

  //使用刚指定的配置项和数据显示图表。
  myChart.setOption(option);

  //# 显示分类
  if (obj.atte.show == true) {
    ligraphic();
  }

  //# 生成分类信息
  function ligraphic(lei) {
    // 生成分类数据
    var children = [];
    var top = 20;
    var color = "#333";
    ; //默认颜色   
    for (var i in fenlei) {
      //当前点击分类为选中红色
      if (lei == i) {
        color = "#f00";
      }
      //定义分类的标题和背景颜色
      children.push({
        type: 'text',
        style: {
          x: 10,
          y: top,
          text: i,
          //分类的标题
          fill: color,
          //默认颜色   
          font: 'bolder 14px "Microsoft YaHei", sans-serif'
        }
      });
      color = "#333";
      top = top + 20;
    }
    myChart.setOption({
      graphic: {
        type: 'group',
        // left: grapleft,  // 定位到右下角
        // top: graptop, // 定位到右下角
        children: children
      }
    });
  }
  //@ 点击一级分类触发
  myChart.on('click', function (params) {
    if (params == null || params.event.target.style.text == null || params.event.target.style.text == "") {
      return;
    }
    //当前分类选中
    ligraphic(params.event.target.style.text);

    //@ 取出数据分类
    var fenlei = {};
    var text = '';
    for (var i in list) {
      //type 分类的标题
      if (list[i].type != '' && list[i].type != null) {
        text = list[i].type;
      } else {
        text = "未分类";
      }
      //text 分类名称
      fenlei[text] = true;
    }
    //重新建索引
    var index = 0;
    for (var i in fenlei) {
      fenlei[i] = index;
      index += 1;
    }

    //@ 设置当前分类下的标题显示
    var legend = [];
    var show;
    for (var i in fenlei) {
      // 当前点击的分类
      if (params.event.target.style.text == i) {
        show = true;
      } else {
        show = false;
      }
      //是示显示该分类
      legend[fenlei[i]] = {
        show: show
      };
    }
    myChart.setOption({
      "legend": legend
    });
  });

  //@ 当前指向的行数据
  myChart.on('mouseover', function (params) {
    document.getElementsByClassName(obj.dom)[0].setAttribute("data-column", params.componentIndex);
  });
  //@  右键图表，隐藏所有选项
  document.getElementsByClassName(obj.dom)[0].oncontextmenu = function (event) {
    //@ 取出数据分类
    var fenlei = {};
    var text = '';
    for (var i in list) {
      //type 分类的标题
      if (list[i].type != '' && list[i].type != null) {
        text = list[i].type;
      } else {
        text = "未分类";
      }
      //text 分类名称
      fenlei[text] = true;
    }
    //重新建索引
    var index = 0;
    for (var i in fenlei) {
      fenlei[i] = index;
      index += 1;
    }

    //@ 设置标题显示
    var legend = [];
    var index = 0;
    //# 遍历分类
    for (var ii in fenlei) {
      var index = fenlei[ii];

      //# 遍历数据
      for (var i in list) {
        if (legend[index] == null) {
          // 添加标题属性
          legend[index] = {
            "selected": {} //是否显示该行数据
          };
        }
        //# 隐藏该行
        // selected  为false时默认不显示该行
        legend[index].selected[list[i].name] = false;
      }
    }
    myChart.setOption({
      legend: legend
    });
    //屏蔽弹出菜单
    return false;
  };
}
;
export default charts;