function lstorage(name, parem) {
    if (!name) {
        return;
    }

    //读取之前的数据
    var datalist = localStorage.getItem("gui-" + name)
    try {
        datalist = JSON.parse(datalist);

    } catch (error) {
        datalist = {}
    }
    if (!datalist || datalist.constructor != Object) {
        datalist = {}
    }


    //读取变量
    if (!parem) {
        return datalist;
    }

    //写入变量
    if (parem.constructor != Object) {
        return;
    }
    //追加数据
    for (var i in parem) {
        datalist[i] = parem[i];
    }
    localStorage.setItem("gui-" + name, JSON.stringify(datalist));

}

export default lstorage;