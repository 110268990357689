import "core-js/modules/es.array.push.js";
import echarts from '@/gui/vue/sdk/echarts.min.js';
function piechart(list, obj) {
  if (list == null || list == "") {
    ElMessageBox.alert('数据不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj == null || obj == "") {
    ElMessageBox.alert('参数不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj.dom == null || obj.dom == "") {
    ElMessageBox.alert('dom元素不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  var dom = document.getElementsByClassName(obj.dom);
  if (dom.length == 0) {
    ElMessageBox.alert('元素class：' + obj.dom + '不存在', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (dom.length > 1) {
    ElMessageBox.alert('元素class：' + obj.dom + '存在多个', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  if (obj.style.width == null) {
    obj.style.width = 800;
  }
  if (obj.style.height == null) {
    obj.style.height = 300;
  }
  //设置尺寸
  dom[0].style.width = obj.style.width + "px";
  dom[0].style.height = obj.style.height + "px";

  // 设置属性
  if (obj.series == null) {
    obj.series = [];
  }

  //配置默认颜色
  var oncolor = ["#f44336", "#9c27b0", "#3f51b5", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#cddc39", "#ffeb3b", "#ff9800", "#ff5722", "#795548", "#9e9e9e", "#607d8b"];

  // 基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(dom[0]);

  //@ 设置边距离
  obj.style.left = parseInt(obj.style.left);
  if (obj.style.left < 0) {
    obj.style.left = 0;
  }
  obj.style.right = parseInt(obj.style.right);
  if (obj.style.right < 0) {
    obj.style.right = 0;
  }
  obj.style.top = parseInt(obj.style.top);
  if (obj.style.top < 0) {
    obj.style.top = 0;
  }
  obj.style.bottom = parseInt(obj.style.bottom);
  if (obj.style.bottom < 0) {
    obj.style.bottom = 0;
  }
  obj.style.left += 40; //左边距离
  obj.style.right += 20; //右边距离
  obj.style.top += 40; //顶边距离
  obj.style.bottom += 30; //下边距离

  //@ 设置标题
  var legend = [];
  var orient;
  var show;
  var index;
  for (var i in list) {
    // 取出分类的索引
    index = 0;
    if (list[i].type != '' && list[i].type != null && fenlei[list[i].type] > 0) {
      index = fenlei[list[i].type];
    }
    //# 添加分类
    if (obj.atte.legend != false) {
      // 遍历数据，设置分类
      for (const ii in list[i].data) {
        console.log(list[i].data[ii].name);
        legend.push(list[i].data[ii].name);
      }
    }

    //# 分类显示方向
    orient = "horizontal";
    if (obj.atte.orient != '') {
      orient = obj.atte.orient;
    }
  }
  //@ 设置数据
  var color = [];
  var series = [];
  var data = [];
  var bar;
  var radius;
  var text;
  var subtext;
  var left;
  var roseType;
  for (var i in list) {
    //# 取出数据
    data = [];
    for (var ii in list[i].data) {
      data.push(list[i].data[ii]); //线条数据
    }
    //# 颜色
    if (obj.series[i].color != '') {
      color = obj.series[i].color;
    }
    //# pie饼状图
    bar = "pie";
    //# pie饼图的半径
    radius = "50%";
    if (obj.series[i].radius != '') {
      radius = obj.series[i].radius;
    }
    //# 是否显示标签
    show = true;
    if (obj.series[i].radius != '') {
      show = obj.series[i].show;
    }
    //# 是否为南丁格尔图
    if (obj.series[i].roseType) {
      roseType = 'area';
    }
    series.push({
      "data": data,
      //饼状图数据
      "name": list[i].name,
      //分类名称
      "type": bar,
      //pie饼状图
      "radius": radius,
      //pie饼状图大小
      "label": {
        show: show // 扇形对应的标签是否显示 
      },

      "roseType": roseType,
      // 是否为南丁格尔图
      "emphasis": {
        // 鼠标移上去的时候，拐点的颜色和样式
        "itemStyle": {
          "borderWidth": 5,
          // 设置border的宽度有多大
          "borderColor": '#fff' // 设置后饼图间会有白色空隙
        }
      }
    });
  }

  //# 标题内容
  if (obj.title.text != '') {
    text = obj.title.text;
  }
  //# 副标题
  if (obj.title.subtext != '') {
    subtext = obj.title.subtext;
  }
  //# 标题位置
  left = 'center';
  if (obj.title.left != '') {
    left = obj.title.left;
  }

  //@ 设置属性
  var option = {
    "color": color,
    "title": {
      "text": text,
      "subtext": subtext,
      "left": left
    },
    "tooltip": {
      "extraCssText": "box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);",
      "backgroundColor": "rgba(255,255,255,0.8)",
      "trigger": "axis",
      "axisPointer": {
        "type": "cross"
      },
      "textStyle": {
        "fontSize": 12
      }
    },
    "legend": {
      orient: orient,
      left: 0,
      data: legend
    },
    "series": series
  };

  //使用刚指定的配置项和数据显示图表。
  myChart.setOption(option);

  //# 显示分类
  if (obj.atte.show == true) {
    ligraphic();
  }

  //# 生成分类信息
  function ligraphic(lei) {
    // 生成分类数据
    var children = [];
    var top = 20;
    var color = "#333";
    ; //默认颜色   

    myChart.setOption({
      graphic: {
        type: 'group',
        children: children
      }
    });
  }
  //@ 点击一级分类触发
  myChart.on('click', function (params) {
    if (params == null || params.event.target.style.text == null || params.event.target.style.text == "") {
      return;
    }
    //当前分类选中
    ligraphic(params.event.target.style.text);
  });

  //@ 当前指向的行数据
  myChart.on('mouseover', function (params) {
    document.getElementsByClassName(obj.dom)[0].setAttribute("data-column", params.componentIndex);
  });
  //@  右键图表，隐藏所有选项
  document.getElementsByClassName(obj.dom)[0].oncontextmenu = function (event) {
    //重新建索引
    var index = 0;
    for (var i in fenlei) {
      // fenlei[i] = index
      index += 1;
    }

    //@ 设置标题显示
    var legend = [];
    var index = 0;
    //# 遍历分类
    for (var ii in fenlei) {
      // var index = fenlei[ii];
      //# 遍历数据
      for (var i in list) {
        if (legend[index] == null) {
          // 添加标题属性
          legend[index] = {
            "selected": {} //是否显示该行数据
          };
        }
        //# 隐藏该行
        // selected  为false时默认不显示该行
        legend[index].selected[list[i].name] = false;
      }
    }
    myChart.setOption({
      legend: legend
    });
    //屏蔽弹出菜单
    return false;
  };
}
;
export default piechart;