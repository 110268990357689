function slice(text, num) {
    if (!num) {
        return text;
    }
    if (text.length <= num) {
        return text;
    }
    var html = '<span onmouseenter="this.getElementsByTagName(\'span\')[0].style.display = \'\'">';
    html += '<span style="display:none;position: absolute;background-color: #fff;z-index: 100;padding-top: 4px;padding: 5px 13px;border-radius: 5px;box-shadow: 0px 0px 3px 2px rgba(168, 168, 168, 0.1);" onmouseleave="this.style.display = \'none\'; event.stopPropagation();">';
    html += text + '</span>';

    html += (text.replace(/\s*/g, "")).slice(0, num);
    html += ' ... </span>';
    return html;
}

export default slice;