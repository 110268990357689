function input(rthis) {
  rthis.$nextTick(function () {
    var targetNode;
    //# 输入框
    if (rthis.$el instanceof HTMLElement != true) {
      ElMessageBox.alert('组件需要添加根节点', '提示信息', {
        confirmButtonText: '确认',
        //dangerouslyUseHTMLString: true,   //是否输出html内容
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });

      return;
    }
    //rthis.$el 获取VUE组件的根节点
    targetNode = rthis.$el.querySelectorAll('input[uscale="auto"]');
    for (var i in targetNode) {
      if (targetNode[i] instanceof HTMLElement != true) {
        continue;
      }
      input_auto(rthis, targetNode[i], 'input');
    }
    //# element 下拉框
    if (rthis.$el instanceof HTMLElement != true) {
      return;
    }
    //rthis.$el 获取VUE组件的根节点
    targetNode = rthis.$el.querySelectorAll('.el-select[uscale="auto"]');
    var elem;
    for (var i in targetNode) {
      if (targetNode[i] instanceof HTMLElement != true) {
        continue;
      }
      elem = targetNode[i].getElementsByClassName('el-input__inner');
      input_auto(rthis, elem[0], 'element');
    }
  });
}
//计算自适应输入框宽度
function input_auto(rthis, targetNode, el) {
  var text = "";
  var clientWidth = 0;

  //得到内容
  text = targetNode.value;

  //# 创建公共框
  var box = document.getElementById("input_uscale_auto");
  if (!box) {
    var addbody = document.getElementsByTagName("body")[0];
    //设置文字内容
    addbody.insertAdjacentHTML("beforeend", '<pre id="input_uscale_auto" style="position: absolute;top: 0px;left: 0px;z-index: -100;visibility: hidden;font-family:\'Microsoft YaHei\',微软雅黑,\'MicrosoftJhengHei\',华文细黑,STHeiti,MingLiu;font-size: 12px;"></pre>');
    box = document.getElementById("input_uscale_auto");
  }
  //# 追加内容
  var max_width = 42;

  //提示文字
  var placeholder = targetNode.getAttribute("placeholder");
  if (text != "") {
    //设置文字内容
    box.innerHTML = text;
    //文字的宽度
    clientWidth = box.clientWidth;
    if (clientWidth < max_width) {
      clientWidth = max_width;
    }
  } else if (placeholder && placeholder != "") {
    //设置文字内容
    box.innerHTML = placeholder;

    //文字的宽度
    clientWidth = box.clientWidth;
    if (clientWidth < max_width) {
      clientWidth = max_width;
    }

    // console.log("重置最小宽度", placeholder, max_width)
  } else {
    // clientWidth = max_width;
    // if (clientWidth < max_width) {
    clientWidth = max_width;
    // }
  }

  //设置input的宽度
  targetNode.style.width = clientWidth + "px";

  // console.log("第一次初始化", targetNode, clientWidth)

  if (typeof targetNode.oninput === "function") {
    return;
  }

  //# 监听内容发生变化
  //添加监听输入框事件 input
  targetNode.oninput = function (e) {
    // console.log("更改输入框宽度")
    var max_width = 42;
    //提示文字
    var placeholder = targetNode.getAttribute("placeholder");
    box = document.getElementById("input_uscale_auto");
    box.innerHTML = e.target.value;
    if (e.target.value != "") {
      if (box.clientWidth < max_width) {
        e.target.style.width = max_width + "px";
      } else {
        e.target.style.width = box.clientWidth + "px";
      }
    } else if (placeholder != "") {
      //设置文字内容
      box.innerHTML = placeholder;
      //文字的宽度
      if (box.clientWidth < max_width) {
        e.target.style.width = max_width + "px";
      } else {
        e.target.style.width = box.clientWidth + "px";
      }
    } else {
      e.target.style.width = max_width + "px";
    }
  };
}
export default input;