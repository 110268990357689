var routes = [
    {
        //数据库
        path: '/admin/sheet',
        component: () => import('@/gui/admin/sheet.vue')

    }, {
        //API列表
        path: '/admin/Classname',
        component: () => import('@/gui/admin/Classname.vue')

    }, {
        //目录列表
        path: '/admin/Items',
        component: () => import('@/gui/admin/Items.vue')

    }, {
        //Dom页面
        path: '/admin/DomProcess',
        component: () => import('@/gui/admin/DomProcess.vue')
    }, {
        //系统日志
        path: '/admin/ErrorLog',
        component: () => import('@/gui/admin/ErrorLog.vue')

    }, {
        //权限管理
        path: '/admin/Juesezu',
        component: () => import('@/gui/admin/Juesezu.vue')

    }, {
        //管理员列表
        path: '/admin/GuanliyuanGuanli',
        component: () => import('@/gui/admin/GuanliyuanGuanli.vue')

    }, {
        //页面调试
        path: '/admin/yemiantiaoshi',
        component: () => import('@/gui/admin/yemiantiaoshi.vue')

    },
    {
        //全局css样式
        path: '/admin/CssStyle',
        component: () => import('@/gui/admin/CssStyle.vue')

    }


];

export default routes;