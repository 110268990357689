import config from '@/gui/vue/config.js';
import axios from 'axios';

//通过GET方式以Post提交数据
const get = function (el) {
  if (el.url == null || el.url == "") {
    ElMessageBox.alert('url地址未配置', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    }).catch(() => {}); // 添加错误捕获
    return;
  }
  ;
  if (el.value != "" && el.value != null) {
    try {
      var data = JSON.parse(JSON.stringify(el.value));
    } catch (error) {
      ElMessageBox.alert(el.url + "<br>" + el.value, '提交参数格式错误', {
        confirmButtonText: '确认',
        //dangerouslyUseHTMLString: true,   //是否输出html内容
        type: 'error' //success 勾   error叉    warning，info 感叹号
      }).catch(() => {}); // 添加错误捕获
      return;
    }

    //遍历对象防止为空
    for (var i in data) {
      if (data[i] == null || data[i] == undefined || data[i] == 'undefined' || data[i] == 'null' || data[i] == NaN || data[i] == "NaN") {
        data[i] = "";
      }
    }
  }
  if (el.alert != "" && el.alert != null) {
    ElMessageBox.confirm(el.alert, '提示信息', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning' //success 勾   error叉    warning，info 感叹号
    }).then(function () {
      formpost(data, el);
    }).catch(function () {});
    return;
  }

  //直接提交
  formpost(data, el);
};

//开始提交服务器
function formpost(data, el) {
  if (el.url.indexOf('http://') >= 0) {
    var iurl = el.url;
  } else if (el.url.indexOf('https://') >= 0) {
    var iurl = el.url;
  } else {
    var iurl = config.url + '/' + el.url;
  }

  //打开加载层
  if (el.load == true) {
    var loading = ElLoading.service({
      lock: false,
      text: '正在加载中...',
      spinner: '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>',
      background: 'rgba(0, 0, 0, 0)',
      customClass: "guiloading"
    });
  }
  typeof data != "object" && (data = {});
  // 从本地缓存中同步获取指定 key 对应的内容
  var token = localStorage.getItem("token");
  if (token != null && token != "" && (data.token == "" || data.token == null)) {
    data.token = token;
  }

  //权限目录
  var guicheck = localStorage.getItem("guicheck");
  if (guicheck != null && guicheck != "" && (data.check == "" || data.check == null)) {
    data.check = guicheck;
  }

  //# 防止重复执行
  if (el.speed > 0) {
    //读取缓存
    var axiostimes = localStorage.getItem("axiostimes");
    // 取当前时间戳
    var getTime = new Date().getTime();
    getTime = Math.floor(getTime / 1000);
    if (axiostimes > 0 && axiostimes > getTime - 3) {
      ElMessage({
        showClose: true,
        //是否自动关闭
        message: '请求间隔（' + (axiostimes - (getTime - 3)) + '）秒',
        type: 'success' //不传灰色，success绿色 ，warning黄色，error红色
      });
      //跳过该次请求
      return;
    }
    //写入缓存
    localStorage.setItem("axiostimes", getTime);
  }
  axios.post(iurl, data, {
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    timeout: 1000 * 100 //超时时间单位MS
    //清求失败  
  }).catch(function (ret) {
    if (el.speed > 0) {
      //写入缓存
      localStorage.setItem("axiostimes", 0);
    }
    if (el.load == true) {
      //隐藏提示框
      loading.close();
    }
    ElMessageBox.alert(ret.message, '请求超时', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    }).catch(() => {}); // 添加错误捕获
    return;
  }).then(function (ret) {
    if (el.speed > 0) {
      //写入缓存
      localStorage.setItem("axiostimes", 0);
    }
    if (el.load == true) {
      //隐藏提示框
      loading.close();
    }
    if (ret == null) {
      return;
    }
    //将json数据转换成数组
    var err = ret.data;
    try {
      err = JSON.parse(err);
    } catch (e) {
      //判断本身是否为对象
      if (typeof err != 'object') {
        ElMessageBox.alert(ret.data, '返回数据错误', {
          confirmButtonText: '确认',
          dangerouslyUseHTMLString: true,
          type: 'error' //success 勾   error叉    warning，info 感叹号
        }).catch(() => {}); // 添加错误捕获

        return;
      }
    }

    //如果传进来是一个方法的话
    if (typeof el.success === "function") {
      //执行这个方法
      el.success(err);
    }
    return;
  });
}
export default get;