
var AdminRoutes = [
    {
        // 站点管理
        path: '/admin/zhandianguanli/zhandianguanli',
        component: () => import('@/admin/zhandianguanli/zhandianguanli.vue')
    }, {
        // 商品分类
        path: '/admin/zhandianfenlei/zhandianfenlei',
        component: () => import('@/admin/zhandianfenlei/zhandianfenlei.vue')
    }, {
        // 标签管理
        path: '/admin/biaoqiangl/biaoqian.gl',
        component: () => import('@/admin/biaoqiangl/biaoqian.gl.vue')
    }, {
        // 会员列表
        path: '/admin/huiyuanliebiao/huiyuanliebiao',
        component: () => import('@/admin/huiyuanliebiao/huiyuanliebiao.vue')

    }, {
        //商品编辑
        path: '/admin/shangpinbianji/shangpinbianji',
        component: () => import('@/admin/shangpinbianji/shangpinbianji.vue')
    }, {
        //后台权限管理
        path: '/admin/quanxiangl/quanxiangl',
        component: () => import('@/admin/quanxiangl/quanxiangl.vue')
    }, {
        //角色组
        path: '/admin/juesezu/juesezu',
        component: () => import('@/admin/juesezu/juesezu.vue')
    }, {
        // 管理员管理
        path: '/admin/guanliyuanguanli/guanliyuanguanli',
        component: () => import('@/admin/guanliyuanguanli/guanliyuanguanli.vue')
    }, {
        // 站点角色组
        path: '/admin/zhandianjuesezu/zhandianjuesezu',
        component: () => import('@/admin/zhandianjuesezu/zhandianjuesezu.vue')
    }, {
        // 站点配置
        path: '/admin/zhandianpeizhi/zhandianpeizhi',
        component: () => import('@/admin/zhandianpeizhi/zhandianpeizhi.vue')
    }, {
        // 配送设置
        path: '/admin/peishong/peishongshezhi/peishong.shezhi',
        component: () => import('@/admin/peishong/peishongshezhi/peishong.shezhi.vue')
    }, {
        // 订单列表
        path: '/admin/dingdanliebiao/dingdanliebiao',
        component: () => import('@/admin/dingdanliebiao/dingdanliebiao.vue')
    }, {
        // 订单分拣
        path: '/admin/dingdanfenjian/dingdanfenjian',
        component: () => import('@/admin/dingdanfenjian/dingdanfenjian.vue')
    }, {
        // 配送骑手
        path: '/admin/peisongqishou/peisongqishou',
        component: () => import('@/admin/peisongqishou/peisongqishou.vue')
    }, {
        // 优惠券列表
        path: '/admin/youhuiquanliebiao/youhuiquanliebiao',
        component: () => import('@/admin/youhuiquanliebiao/youhuiquanliebiao.vue')
    }, {
        // 手动发送优惠券
        path: '/admin/shoudongfasong/shoudongfasong',
        component: () => import('@/admin/shoudongfasong/shoudongfasong.vue')
    }, {
        // 短信列表
        path: '/admin/duanxinliebiao/duanxinliebiao',
        component: () => import('@/admin/duanxinliebiao/duanxinliebiao.vue')
    }, {
        // 今日秒杀
        path: '/admin/jinrimiaosha/jinrimiaosha',
        component: () => import('@/admin/jinrimiaosha/jinrimiaosha.vue')
    }, {
        // 配送单管理
        path: '/admin/peisongdanguanli/peisongdanguanli',
        component: () => import('@/admin/peisongdanguanli/peisongdanguanli.vue')
    }, {
        // 售后设置
        path: '/admin/shouhoushezhi/shouhoushezhi',
        component: () => import('@/admin/shouhoushezhi/shouhoushezhi.vue')
    }, {
        // 支付列表
        path: '/admin/zhifuliebiao/zhifuliebiao',
        component: () => import('@/admin/zhifuliebiao/zhifuliebiao.vue')
    }, {
        // 售后订单
        path: '/admin/shouhoudingdan/shouhoudingdan',
        component: () => import('@/admin/shouhoudingdan/shouhoudingdan.vue')
    }, {
        // 评价骑手标签
        path: '/admin/qishoupingjia/qishoupingjia',
        component: () => import('@/admin/qishoupingjia/qishoupingjia.vue')
    }, {
        // 商品评价标签
        path: '/admin/shangpinpingjia/shangpinpingjia',
        component: () => import('@/admin/shangpinpingjia/shangpinpingjia.vue')
    }, {
        // 优惠券分类
        path: '/admin/youhuiquanfenlei/youhuiquanfenlei',
        component: () => import('@/admin/youhuiquanfenlei/youhuiquanfenlei.vue')
    }, {
        // 商品属性分类
        path: '/admin/shangpinshuxingfenlei/shangpinshuxingfenlei',
        component: () => import('@/admin/shangpinshuxingfenlei/shangpinshuxingfenlei.vue')
    }, {
        // 商品属性列表
        path: '/admin/shangpinshuxing/shangpinshuxing',
        component: () => import('@/admin/shangpinshuxing/shangpinshuxing.vue')
    }, {
        // 拉新设置
        path: '/admin/laxinshezhi/laxinshezhi',
        component: () => import('@/admin/laxinshezhi/laxinshezhi.vue')
    }, {
        // 拉新列表
        path: '/admin/laxinliebiao/laxinliebiao',
        component: () => import('@/admin/laxinliebiao/laxinliebiao.vue')
    }, {
        // 分销设置
        path: '/admin/fenxiaoshezhi/fenxiaoshezhi',
        component: () => import('@/admin/fenxiaoshezhi/fenxiaoshezhi.vue')
    }, {
        // 分销列表
        path: '/admin/fenxiaoliebiao/fenxiaoliebiao',
        component: () => import('@/admin/fenxiaoliebiao/fenxiaoliebiao.vue')
    }, {
        // 分销注册列表
        path: '/admin/fenxiaozhuce/fenxiaozhuce',
        component: () => import('@/admin/fenxiaozhuce/fenxiaozhuce.vue')
    }, {
        // 分销收益明细
        path: '/admin/fenxiaoshouyi/fenxiaoshouyi',
        component: () => import('@/admin/fenxiaoshouyi/fenxiaoshouyi.vue')
    }, {
        // 赠品活动
        path: '/admin/zengpinhuodong/zengpinhuodong',
        component: () => import('@/admin/zengpinhuodong/zengpinhuodong.vue')
    }, {
        // 个人中心幻灯片
        path: '/admin/huandengpian/huandengpian',
        component: () => import('@/admin/huandengpian/huandengpian.vue')
    }, {
        // 底部导航
        path: '/admin/dibudaohang/dibudaohang',
        component: () => import('@/admin/dibudaohang/dibudaohang.vue')
    }, {
        // 图标分类管理
        path: '/admin/tubiaofenlei/tubiaofenlei',
        component: () => import('@/admin/tubiaofenlei/tubiaofenlei.vue')
    }, {
        // 图标链接
        path: '/admin/tubiaolianjie/tubiaolianjie',
        component: () => import('@/admin/tubiaolianjie/tubiaolianjie.vue')
    }, {
        // 客服账号
        path: '/admin/kefuzhanghao/kefuzhanghao',
        component: () => import('@/admin/kefuzhanghao/kefuzhanghao.vue')
    }, {
        // 客服离线设置
        path: '/admin/kefulixianshezhi/kefulixianshezhi',
        component: () => import('@/admin/kefulixianshezhi/kefulixianshezhi.vue')
    }, {
        // 快捷回复
        path: '/admin/kuaijiehuifu/kuaijiehuifu',
        component: () => import('@/admin/kuaijiehuifu/kuaijiehuifu.vue')
    }, {
        // 客服后台
        path: '/admin/kefu/message',
        component: () => import('@/admin/kefu/message.vue')
    }, {
        // 自动同步设置
        path: '/admin/zidongtongbushezhi/zidongtongbushezhi',
        component: () => import('@/admin/zidongtongbushezhi/zidongtongbushezhi.vue')
    }, {
        // 付款超时同步日志
        path: '/admin/fukuanchaoshilog/fukuanchaoshilog',
        component: () => import('@/admin/fukuanchaoshilog/fukuanchaoshilog.vue')
    }, {
        // 推广图片管理
        path: '/admin/tuiguangtupian/tuiguangtupian',
        component: () => import('@/admin/tuiguangtupian/tuiguangtupian.vue')
    }, {
        // 首页幻灯片
        path: '/admin/homepageslideshow/homepageslideshow',
        component: () => import('@/admin/homepageslideshow/homepageslideshow.vue')
    }, {
        // 首页轮播广告
        path: '/admin/lunboguanggao/lunboguanggao',
        component: () => import('@/admin/lunboguanggao/lunboguanggao.vue')
    }, {
        // 公告管理
        path: '/admin/gonggao/gonggao',
        component: () => import('@/admin/gonggao/gonggao.vue')
    }, {
        // 商品规格
        path: '/admin/shangpinguige/shangpinguige',
        component: () => import('@/admin/shangpinguige/shangpinguige.vue')
    }, {
        // 商品导航
        path: '/admin/shangpindaohang/shangpindaohang',
        component: () => import('@/admin/shangpindaohang/shangpindaohang.vue')
    }, {
        // 商品评价管理
        path: '/admin/shangpinpingjiaguanli/shangpinpingjiaguanli',
        component: () => import('@/admin/shangpinpingjiaguanli/shangpinpingjiaguanli.vue')
    }, {
        // 骑手评价管理
        path: '/admin/qishoupingjiaguanli/qishoupingjiaguanli',
        component: () => import('@/admin/qishoupingjiaguanli/qishoupingjiaguanli.vue')
    }, {
        // 秒杀专场
        path: '/admin/miaoshazhuanchang/miaoshazhuanchang',
        component: () => import('@/admin/miaoshazhuanchang/miaoshazhuanchang.vue')
    }, {
        path: '/admin/miaoshazhuanchang/miaoshashezhi',
        component: () => import('@/admin/miaoshazhuanchang/miaoshashezhi.vue')
    }, {
        // 秒杀商品列表
        path: '/admin/miaoshagoodslists/miaoshagoodslists',
        component: () => import('@/admin/miaoshagoodslists/miaoshagoodslists.vue')
    }, {
        //领取优惠券记录
        path: '/admin/youhuiquanliebiao/youhuiquanlist',
        component: () => import('@/admin/youhuiquanliebiao/youhuiquanlist.vue')
    }, {
        // 新人专享商品列表
        path: '/admin/xinrenzhuanxiang/xinrenzhuanxiang',
        component: () => import('@/admin/xinrenzhuanxiang/xinrenzhuanxiang.vue')
    }, {
        // 新人专享设置
        path: '/admin/zhuanxiangshezhi/zhuanxiangshezhi',
        component: () => import('@/admin/zhuanxiangshezhi/zhuanxiangshezhi.vue')
    }, {
        // 客服常见问题
        path: '/admin/changjianwenti/changjianwenti',
        component: () => import('@/admin/changjianwenti/changjianwenti.vue')
    }, {
        // 充值设置
        path: '/admin/chongzhishezhi/chongzhishezhi',
        component: () => import('@/admin/chongzhishezhi/chongzhishezhi.vue')
    }, {
        // 充值订单
        path: '/admin/chongzhidingdan/chongzhidingdan',
        component: () => import('@/admin/chongzhidingdan/chongzhidingdan.vue')
    }, {
        // 用户退款
        path: '/admin/yonghutuikuan/yonghutuikuan',
        component: () => import('@/admin/yonghutuikuan/yonghutuikuan.vue')
    }, {
        // 骑手提现设置
        path: '/admin/qishoutixianset/qishoutixianset',
        component: () => import('@/admin/qishoutixianset/qishoutixianset.vue')
    }, {
        // 站点提现
        path: '/admin/zhandiantixian/zhandiantixian',
        component: () => import('@/admin/zhandiantixian/zhandiantixian.vue')
    }, {
        // 骑手提现
        path: '/admin/qishoutixian/qishoutixian',
        component: () => import('@/admin/qishoutixian/qishoutixian.vue')
    }, {
        // 站点提现审核列表
        path: '/admin/zhandiantixianshenhe/zhandiantixianshenhe',
        component: () => import('@/admin/zhandiantixianshenhe/zhandiantixianshenhe.vue')
    }, {
        // 站点结算设置
        path: '/admin/zhandianjiesuanset/zhandianjiesuanset',
        component: () => import('@/admin/zhandianjiesuanset/zhandianjiesuanset.vue')
    }, {
        // 库区域管理
        path: '/admin/kuquyuguanli/kuquyuguanli',
        component: () => import('@/admin/kuquyuguanli/kuquyuguanli.vue')
    }, {
        // 库存管理
        path: '/admin/kucunguanli/kucunguanli',
        component: () => import('@/admin/kucunguanli/kucunguanli.vue')
    }, {
        // 商品操作记录
        path: '/admin/operationrecords/operationrecords',
        component: () => import('@/admin/operationrecords/operationrecords.vue')
    }, {
        // 渠道供货商列表
        path: '/admin/channelsforsuppliers/channelsforsuppliers',
        component: () => import('@/admin/channelsforsuppliers/channelsforsuppliers.vue')
    }, {
        // 采购列表
        path: '/admin/caigouliebiao/caigouliebiao',
        component: () => import('@/admin/caigouliebiao/caigouliebiao.vue')
    }, {
        // 微信机器人
        path: '/admin/wechatrobot/wechatrobot',
        component: () => import('@/admin/wechatrobot/wechatrobot.vue')
    }, {
        // 微信群列表
        path: '/admin/wechatgrouplist/wechatgrouplist',
        component: () => import('@/admin/wechatgrouplist/wechatgrouplist.vue')

    }, {
        // 设置群公告
        path: '/admin/setqungonggao/setqungonggao',
        component: () => import('@/admin/setqungonggao/setqungonggao.vue')

    }, {
        // 设置群公告
        path: '/admin/sendqunmessage/sendqunmessage',
        component: () => import('@/admin/sendqunmessage/sendqunmessage.vue')

    }, {
        // 微信群成员列表
        path: '/admin/groupmemberlist/groupmemberlist',
        component: () => import('@/admin/groupmemberlist/groupmemberlist.vue')
    }, {
        path: '/admin/groupmemberlist/addruqunerweima',
        component: () => import('@/admin/groupmemberlist/addruqunerweima.vue')
    }
    , {
        // 全局设置概括
        path: '/admin/quanjushezhi/quanjushezhi',
        component: () => import('@/admin/quanjushezhi/quanjushezhi.vue')

    }
    , {
        // 用户提现设置
        path: '/admin/userwithdrawalset/userwithdrawalset',
        component: () => import('@/admin/userwithdrawalset/userwithdrawalset.vue')

    },
    {
        // 分拣专员
        path: '/admin/fenjianzhuanyuan/fenjianzhuanyuan',
        component: () => import('@/admin/fenjianzhuanyuan/fenjianzhuanyuan.vue')

    },
    {
        // 采购专员
        path: '/admin/caigouzhuanyuan/caigouzhuanyuan',
        component: () => import('@/admin/caigouzhuanyuan/caigouzhuanyuan.vue')

    },
    {
        // 搜索关键词
        path: '/admin/searchkeyword/searchkeyword',
        component: () => import('@/admin/searchkeyword/searchkeyword.vue')

    },
    {
        // 优惠券设置
        path: '/admin/youhuiquanset/youhuiquanset',
        component: () => import('@/admin/youhuiquanset/youhuiquanset.vue')

    },
    {
        // APP基本设置
        path: '/admin/jibenshezhi/jibenshezhi',
        component: () => import('@/admin/jibenshezhi/jibenshezhi.vue')

    },
    {
        // 售后基本设置
        path: '/admin/shouhoujibenset/shouhoujibenset',
        component: () => import('@/admin/shouhoujibenset/shouhoujibenset.vue')
    },
    {
        // 文档管理
        path: '/admin/wendangguanli/wendangguanli',
        component: () => import('@/admin/wendangguanli/wendangguanli.vue')
    },
    {
        // 用户订单支付
        path: '/admin/dingdanzhifu/dingdanzhifu',
        component: () => import('@/admin/dingdanzhifu/dingdanzhifu.vue')
    },
    {
        // 订单取消
        path: '/admin/dingdanquxiao/dingdanquxiao',
        component: () => import('@/admin/dingdanquxiao/dingdanquxiao.vue')
    },
    {
        // 申请售后退款
        path: '/admin/dingdanzhifu/shouhoutuikuangl',
        component: () => import('@/admin/dingdanzhifu/shouhoutuikuangl.vue')
    },
    {
        // 分拣缺货
        path: '/admin/dingdanzhifu/quehuotuikuangl',
        component: () => import('@/admin/dingdanzhifu/quehuotuikuangl.vue')

    },
    {
        // 余额 - 余额订单支付
        path: '/admin/dingdanzhifu/yuezhifujilugl',
        component: () => import('@/admin/dingdanzhifu/yuezhifujilugl.vue')

    },
    {
        // 余额 - 订单取消
        path: '/admin/dingdanzhifu/yuedingdanquxiaogl',
        component: () => import('@/admin/dingdanzhifu/yuedingdanquxiaogl.vue')

    },
    {
        // 余额 - 分拣缺货退款
        path: '/admin/dingdanzhifu/yueshengqingshouhou',
        component: () => import('@/admin/dingdanzhifu/yueshengqingshouhou.vue')

    },
    {
        // 余额 - 申请售后
        path: '/admin/dingdanzhifu/yuequehuovtuikuan',
        component: () => import('@/admin/dingdanzhifu/yuequehuovtuikuan.vue')
    },
    {
        // 财务 - 站点余额
        path: '/admin/zhandianyue/zhandianyue',
        component: () => import('@/admin/zhandianyue/zhandianyue.vue')
    },
    {
        // 分拣 - 缺货退款原图
        path: '/admin/shouhoushezhi/children/quehuotuikuan',
        component: () => import('@/admin/shouhoushezhi/children/quehuotuikuan.vue')
    },
    {
        // 分拣 - 分拣基本设置
        path: '/admin/fenjianbasicset/fenjianbasicset',
        component: () => import('@/admin/fenjianbasicset/fenjianbasicset.vue')
    },
    {
        // 财务 - 分拣差额退款
        path: '/admin/fenjianchaetuikuan/fenjianchaetuikuan',
        component: () => import('@/admin/fenjianchaetuikuan/fenjianchaetuikuan.vue')
    }, {
        // 财务 - 分拣重量差额退款
        path: '/admin/fenjianzhongliangtuik/fenjianzhongliangtuik',
        component: () => import('@/admin/fenjianzhongliangtuik/fenjianzhongliangtuik.vue')
    }, {
        // 财务 - 余额交易记录
        path: '/admin/yuejiaoyijilu/yuejiaoyijilu',
        component: () => import('@/admin/yuejiaoyijilu/yuejiaoyijilu.vue')
    }
    , {
        // 用户 - 用户登录记录
        path: '/admin/yonghudengjijilu/yonghudengjijilu',
        component: () => import('@/admin/yonghudengjijilu/yonghudengjijilu.vue')
    }
    , {
        // 促销 - 打折专区
        path: '/admin/dazhezhuanqu/dazhezhuanqu',
        component: () => import('@/admin/dazhezhuanqu/dazhezhuanqu.vue')
    }, {
        // 促销 - 打折商品列表
        path: '/admin/dazheshangpin/dazheshangpin',
        component: () => import('@/admin/dazheshangpin/dazheshangpin.vue')
    }, {
        // 商品 - 商品类别专区
        path: '/admin/shangpinleibie/shangpinleibie',
        component: () => import('@/admin/shangpinleibie/shangpinleibie.vue')
    }
    , {
        // 促销 - 邀请砍价设置
        path: '/admin/yaoqingkanjia/yaoqingkanjia',
        component: () => import('@/admin/yaoqingkanjia/yaoqingkanjia.vue')
    }, {
        // 促销 - 邀请砍价明细
        path: '/admin/kanjiamingxi/kanjiamingxi',
        component: () => import('@/admin/kanjiamingxi/kanjiamingxi.vue')
    }, {
        // 设置 - 显示热门分类
        path: '/admin/remenfenlei/remenfenlei',
        component: () => import('@/admin/remenfenlei/remenfenlei.vue')
    }, {
        // 设置 - 自定义链接
        path: '/admin/zidingyilianjie/zidingyilianjie',
        component: () => import('@/admin/zidingyilianjie/zidingyilianjie.vue')
    }
    , {
        // 促销 - 助力设置
        path: '/admin/zhulishezhi/zhulishezhi',
        component: () => import('@/admin/zhulishezhi/zhulishezhi.vue')
    }, {
        // 促销 - 助力优惠券
        path: '/admin/zhuliyouhuiquan/zhuliyouhuiquan',
        component: () => import('@/admin/zhuliyouhuiquan/zhuliyouhuiquan.vue')
    }
    , {
        // 促销 - 助力记录明细
        path: '/admin/zhulijilumingxi/zhulijilumingxi',
        component: () => import('@/admin/zhulijilumingxi/zhulijilumingxi.vue')
    }, {
        // 财务 - 记账
        path: '/admin/jizhang/jizhang',
        component: () => import('@/admin/jizhang/jizhang.vue')
    }, {
        // 促销 - 抽奖设置
        path: '/admin/choujiangshezhi/choujiangshezhi',
        component: () => import('@/admin/choujiangshezhi/choujiangshezhi.vue')
    }, {
        // 促销 - 抽奖排序设置
        path: '/admin/choujiangpaiqishezhi/choujiangpaiqishezhi',
        component: () => import('@/admin/choujiangpaiqishezhi/choujiangpaiqishezhi.vue')
    }, {
        // 促销 - 中奖用户列表
        path: '/admin/zhongjiangyonghu/zhongjiangyonghu',
        component: () => import('@/admin/zhongjiangyonghu/zhongjiangyonghu.vue')
    }, {
        // 促销 - VIP充值金额
        path: '/admin/vipchongzhijine/vipchongzhijine',
        component: () => import('@/admin/vipchongzhijine/vipchongzhijine.vue')
    }, {
        // 促销 - VIP设置
        path: '/admin/vipshezhi/vipshezhi',
        component: () => import('@/admin/vipshezhi/vipshezhi.vue')
    }, {
        path: '/admin/vipchongzhijine/vipzhifumingxigl',
        component: () => import('@/admin/vipchongzhijine/vipzhifumingxigl.vue')
    }, {
        path: '/admin/vipchongzhijine/vipshangpin',
        component: () => import('@/admin/vipchongzhijine/vipshangpin.vue')
    }, {
        path: '/admin/hongbao/hongbaoshezhi',
        component: () => import('@/admin/hongbao/hongbaoshezhi.vue')
    }, {
        path: '/admin/hongbao/hongbaomingxi',
        component: () => import('@/admin/hongbao/hongbaomingxi.vue')
    }, {
        path: '/admin/jizhang/caiwutongji',
        component: () => import('@/admin/jizhang/caiwutongji.vue')
    }, {
        path: '/admin/kefulixianshezhi/qiyekefuerweima',
        component: () => import('@/admin/kefulixianshezhi/qiyekefuerweima.vue')

    }, {
        path: '/admin/kefulixianshezhi/qiyeuserlist',
        component: () => import('@/admin/kefulixianshezhi/qiyeuserlist.vue')
    }, {
        path: '/admin/kefulixianshezhi/qiyexiaoxilist',
        component: () => import('@/admin/kefulixianshezhi/qiyexiaoxilist.vue')
    }, {
        path: '/admin/zhandianpeizhi/zhandiandayingji',
        component: () => import('@/admin/zhandianpeizhi/zhandiandayingji.vue')
    }, {
        path: '/admin/pengyouquan/fenxiangshezhi',
        component: () => import('@/admin/pengyouquan/fenxiangshezhi.vue')
    }, {
        path: '/admin/pengyouquan/fenxiangmingxilist',
        component: () => import('@/admin/pengyouquan/fenxiangmingxilist.vue')
    }, {
        path: '/admin/pengyouquan/peishongzhitidian',
        component: () => import('@/admin/peishong/peishongshezhi/peishongzhitidian.vue')
    }, {
        path: '/admin/zhandianpeizhi/jiaoyilunbo',
        component: () => import('@/admin/zhandianpeizhi/jiaoyilunbo.vue')

    }, {
        path: '/admin/zengpinhuodong/zengsonggoods',
        component: () => import('@/admin/zengpinhuodong/zengsonggoods.vue')

    }, {
        path: '/admin/huiyuanliebiao/userliulangl',
        component: () => import('@/admin/huiyuanliebiao/userliulangl.vue')

    }, {
        path: '/admin/kefulixianshezhi/qiyekefulog',
        component: () => import('@/admin/kefulixianshezhi/qiyekefulog.vue')
    }, {
        path: '/admin/huiyuanliebiao/tuiguangqudao',
        component: () => import('@/admin/huiyuanliebiao/tuiguangqudao.vue')
    }, {
        path: '/admin/huiyuanliebiao/tuiguangxiaoqu',
        component: () => import('@/admin/huiyuanliebiao/tuiguangxiaoqu.vue')
    }, {
        path: '/admin/huiyuanliebiao/tuiguanglog',
        component: () => import('@/admin/huiyuanliebiao/tuiguanglog.vue')
    }, {
        path: '/admin/huiyuanliebiao/tuiguangzhanghu',
        component: () => import('@/admin/huiyuanliebiao/tuiguangzhanghu.vue')
    }, {
        path: '/admin/huiyuanliebiao/dingdanpingjia',
        component: () => import('@/admin/huiyuanliebiao/dingdanpingjia.vue')
    }, {
        path: '/admin/huiyuanliebiao/dajiawengl',
        component: () => import('@/admin/huiyuanliebiao/dajiawengl.vue')
    }, {
        path: '/admin/zhandianpeizhi/xuanfuanniu',
        component: () => import('@/admin/zhandianpeizhi/xuanfuanniu.vue')
    }, {
        path: '/admin/jizhang/tongji',
        component: () => import('@/admin/jizhang/tongji.vue')
    }, {
        path: '/admin/jizhang/zhifubaomingxi',
        component: () => import('@/admin/jizhang/zhifubaomingxi.vue')
    }





















































];
export default AdminRoutes;