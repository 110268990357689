//获取当前的时间戳
var stime = function () {
    return Date.parse(new Date()) / 1000;
}
//将时间转换成时间戳
var strtotime = function (time) {
    if (time == null || time == "") {
        return "";
    }
    try {
        var news = time.replace(/-/g, '/');
    } catch (error) {
        return;
    }
    var date = new Date(news);
    time = date.getTime().toString();
    return time.substr(0, 10);
}
//时间戳转换成时间，和PHP格式一样
var sdate = function (e, time) {
    if (time == null || time == "") {
        return "";
    }
    var time = new Date(parseInt(time) * 1000);
    var Y = time.getFullYear();
    var m = time.getMonth() + 1;
    if (m < 10) {
        m = "0" + m;
    };
    var d = time.getDate();
    if (d < 10) {
        d = "0" + d;
    };
    var H = time.getHours();
    if (H < 10) {
        H = "0" + H;
    };
    var mm = time.getMinutes();
    if (mm < 10) {
        mm = "0" + mm;
    };
    var s = time.getSeconds();
    if (s < 10) {
        s = "0" + s;
    };
    time = "";
    if (e.indexOf("Y-") != -1) {
        time = Y + "-";
    } else if (e.indexOf("Y/") != -1) {
        time = Y + "/";
    } else if (e.indexOf("Y") != -1) {
        time = Y;
    }

    if (e.indexOf("m-") != -1) {
        time = time + m + "-";
    } else if (e.indexOf("m/") != -1) {
        time = time + m + "/";
    } else if (e.indexOf("m") != -1) {
        time = time + m;
    }

    if (e.indexOf("d ") != -1) {
        time = time + d + " ";
    } else if (e.indexOf("d") != -1) {
        time = time + d;
    }
    if (e.indexOf("H:") != -1) {
        time = time + H + ":";
    } else if (e.indexOf("H") != -1) {
        time = time + H;
    }
    if (e.indexOf("i:") != -1) {
        time = time + mm + ":";
    } else if (e.indexOf("i") != -1) {
        time = time + mm;
    }
    if (e.indexOf("s") != -1) {
        time = time + s + "";
    }
    return time;
}



//将标转时间转时间戳
var stime_to = function (time) {
    return Date.parse(time) / 1000;
}
//将时间戳转标转时间
var stime_data = function (time) {
    return new Date(time * 1000);
}

//将标准时间转自定义时间格式
var standard_time = function (e, time) {
    if (time == null || time == "") {
        return "";
    }
    var time = new Date(time);
    var Y = time.getFullYear();
    var m = time.getMonth() + 1;
    if (m < 10) {
        m = "0" + m;
    };
    var d = time.getDate();
    if (d < 10) {
        d = "0" + d;
    };
    var H = time.getHours();
    if (H < 10) {
        H = "0" + H;
    };
    var mm = time.getMinutes();
    if (mm < 10) {
        mm = "0" + mm;
    };
    var s = time.getSeconds();
    if (s < 10) {
        s = "0" + s;
    };
    time = "";
    if (e.indexOf("Y-") != -1) {
        time = Y + "-";
    } else if (e.indexOf("Y/") != -1) {
        time = Y + "/";
    } else if (e.indexOf("Y") != -1) {
        time = Y;
    }

    if (e.indexOf("m-") != -1) {
        time = time + m + "-";
    } else if (e.indexOf("m/") != -1) {
        time = time + m + "/";
    } else if (e.indexOf("m") != -1) {
        time = time + m;
    }

    if (e.indexOf("d ") != -1) {
        time = time + d + " ";
    } else if (e.indexOf("d") != -1) {
        time = time + d;
    }
    if (e.indexOf("H:") != -1) {
        time = time + H + ":";
    } else if (e.indexOf("H") != -1) {
        time = time + H;
    }
    if (e.indexOf("i:") != -1) {
        time = time + mm + ":";
    } else if (e.indexOf("i") != -1) {
        time = time + mm;
    }
    if (e.indexOf("s") != -1) {
        time = time + s + "";
    }
    return time;
}

// 将自定义时间格式转标准时间
var custom_time = function (time) {
    // 先把自定义时间转为时间戳
    try {
        var news = time.replace(/-/g, '/');
    } catch (error) {
        return;
    }
    var date = new Date(news);
    var time = date.getTime().toString();
    var times = time.substr(0, 10);
    // 时间戳转标准时间
    return new Date(times * 1000);
}






//将时间戳转换成星期几
var stimeday = function (time) {

    //将时间戳转标转时间
    time = new Date(time * 1000)

    //计算星期几
    time = time.getDay();
    switch (time) {
        case 1:
            return '星期一'
        case 2:
            return '星期二'
        case 3:
            return '星期三'
        case 4:
            return '星期四'
        case 5:
            return '星期五'
        case 6:
            return '星期六'
        default:
            return '星期日'
    }
}


export default {
    stime: stime,
    strtotime: strtotime,
    sdate: sdate,
    stime_to: stime_to,
    stime_data: stime_data,
    stimeday: stimeday,
    standard_time: standard_time,
    custom_time: custom_time,

};