import config from '@/gui/vue/config.js';
import axios from 'axios';
var imagepaste = function (el) {
  if (el == null || el == "") {
    ElMessageBox.alert('参数不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  } else if (el.event == null || el.event == "") {
    ElMessageBox.alert('元素对象不能为空', '提示信息', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }
  ;
  if (el.event.clipboardData || el.event.originalEvent) {
    var clipboardData = el.event.clipboardData || el.event.originalEvent.clipboardData;
    if (clipboardData != null && clipboardData.items) {
      // 获取属性
      var items = clipboardData.items;
      var blob = null;

      //在items里找粘贴的image,据上面分析,需要循环  
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) {
          continue;
        }
        //getAsFile() 此方法只是living standard firefox ie11 并不支持        
        blob = items[i].getAsFile();
      }
      //未获取到Base64
      if (blob == null) {
        return;
      }
      //阻止默认行为即不让剪贴板内容在div中显示出来
      el.event.preventDefault();
      var reader = new FileReader();
      reader.onload = function (event) {
        // el.event.target.result 即为图片的Base64编码字符串
        var base64_str = event.target.result;

        //可以在这里写上传逻辑 直接将base64编码的字符串上传（可以尝试传入blob对象，看看后台程序能否解析）
        upbase64_Paste(base64_str, el);
      };
      reader.readAsDataURL(blob);
      return;
    }
  }
};
function upbase64_Paste(file, el) {
  //打开加载层
  if (el.load == true) {
    //显示加载层
    var loading = ElLoading.service({
      lock: false,
      text: '正在加载中...',
      spinner: '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>',
      background: 'rgba(0, 0, 0, 0)',
      customClass: "guiloading"
    });
  }
  axios.post(config.upload + '/guisdk/gui/imagepaste', {
    base64: file,
    path: el.path
  }, {
    headers: {
      'Accept': 'text/html',
      'content-type': 'application/x-www-form-urlencoded' //自定义请求头信息
    }
    //清求失败  
  }).catch(function (ret) {
    if (el.load == true) {
      //隐藏提示框
      loading.close();
    }
    ElMessageBox.alert(ret.message, '请求超时', {
      confirmButtonText: '确认',
      type: 'error' //success 勾   error叉    warning，info 感叹号
    });

    return;
  }).then(function (ret) {
    if (el.load == true) {
      //隐藏提示框
      loading.close();
    }
    if (ret == null) {
      return;
    }
    //将json数据转换成数组
    var err = ret.data;
    try {
      err = JSON.parse(err);
    } catch (e) {
      //判断本身是否为对象
      if (typeof err != 'object') {
        ElMessageBox.alert(ret.data, '返回数据错误', {
          confirmButtonText: '确认',
          dangerouslyUseHTMLString: true,
          type: 'error' //success 勾   error叉    warning，info 感叹号
        });
      }
    }

    if (err.err != 1) {
      ElMessageBox.alert(err.errsms, '图片上传错误', {
        confirmButtonText: '确认',
        dangerouslyUseHTMLString: true,
        type: 'error' //success 勾   error叉    warning，info 感叹号
      });
    }

    //如果传进来是一个方法的话
    if (typeof el.success === "function") {
      //执行这个方法
      el.success(err);
    }
    return;
  });
}
export default imagepaste;